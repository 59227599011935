import NewsletterCompaniesApi from '../../libs/api/newsletter_companies';
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useHistory } from 'react-router-dom';

const api = new NewsletterCompaniesApi('newsletter');

const ShowEmailPage = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [html, setHtml] = useState('');
  const {
    match: {
      params: { token },
    },
  } = props;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {
          data: { html },
        } = await api.get('', { params: { token } });
        setHtml(html);
      } catch (error) {
        console.log(error);
        history.push('/404');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    // <div
    //   className={styles.container}
    //   dangerouslySetInnerHTML={{ __html: html }}
    // ></div>
    <iframe className={styles.container} src='about:blank' srcDoc={html} />
  );
};

export default ShowEmailPage;
