import Api from 'libs/api';

class ContactsApi extends Api {
  constructor() {
    super('');
  }

  getContactLog(id, params = {}) {
    return this.buildQuery('get', params, `contacts/${id}/log`);
  }

  getAllContacts(params) {
    return this.buildQuery('get', params, 'contacts');
  }

  getAllContactsFields(params) {
    return this.buildQuery('get', params, 'contact_groups/fields');
  }

  getAllContactsExcel(params) {
    return this.buildQuery('get', params, 'contacts.xlsx');
  }

  getPlaceHolder(id) {
    return this.buildQuery('get', {}, `companies/${id}/fetch_contact_fields`);
  }

  refreshContactGroupSize(id) {
    return this.buildQuery(
      'get',
      {},
      `companies/${id}/refresh_contact_group_size`,
    );
  }

  getAllGroups(params) {
    return this.buildQuery('get', params, 'contact_groups');
  }

  getGroup(id, params) {
    return this.buildQuery('get', params, `contact_groups/${id}`);
  }

  getContactsImports(id) {
    return this.buildQuery('get', {}, `contact_imports?company_id=${id}`);
  }

  deleteContactsImportsHistory(id) {
    return this.buildQuery('delete', {}, `contact_imports/${id}/soft_destroy`);
  }

  createGroup(data) {
    return this.buildQuery('post', data, 'contact_groups');
  }

  updateGroup(data, id) {
    return this.buildQuery('put', data, `contact_groups/${id}`);
  }

  deleteGroup(id) {
    return this.buildQuery('post', {}, `contact_groups/${id}/soft_delete`);
  }

  deleteContactById(id) {
    return this.buildQuery('delete', {}, `contacts/${id}`);
  }

  changeSelectedContactsStatus(data) {
    return this.buildQuery('post', { data }, 'contacts/collect_update');
  }

  deleteSelectedContacts(data) {
    return this.buildQuery('post', { data }, 'contacts/collect_delete');
  }

  renewed(data) {
    return this.buildQuery('post', { data }, 'contacts/renewed');
  }

  uploadContactsFromFile(file, id) {
    return this.buildQuery(
      'post',
      {
        headers: {
          'content-type':
            'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
        // params: {
        //     headers: {
        //         'content-type':
        //             'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        //     },
        // },
        data: file,
      },
      `contact_imports?company_id=${id}`,
    );
  }
}

export default ContactsApi;
