import { useDispatch, useSelector } from 'react-redux';

import {
  getDomainsRequest,
  setCurrentDomain,
  getDomains,
  setCurrentType,
  setSelectedCurrentType,
  resetState,
  toggleDataTypeModal,
  setDataTypeList,
} from './actions';
import {
  domainsDataSelector,
  domainsCurrentSelector,
  domainsUser,
  getIsVisibleSetDataTypeModal,
} from './selectors';
import { useEffect, useState } from 'react';
import { primaryVariables } from '../../common/constants';
import { useHistory } from 'react-router-dom';

export const useDomains = () => {
  const dispatch = useDispatch();
  const domains = useSelector(domainsDataSelector);
  const currentDomain = useSelector(domainsCurrentSelector);
  const isVisibleSetDataTypeModal = useSelector(getIsVisibleSetDataTypeModal);
  const currentDataTypeList = useSelector(
    (state) => state.domains.currentDataTypeList,
  );
  const currentSelectedDataType = useSelector(
    (state) => state.domains.currentSelectedDataType,
  );

  const {
    currentNewsletter,
    currentNotification,
    currentSMS,
    currentWhatsapp,
  } = useSelector((state) => state.domains);
  const history = useHistory();
  const user = useSelector(domainsUser);
  function handleGetDomains() {
    dispatch(getDomainsRequest());
  }

  function handleSetCurrentDomain(currentDomain) {
    dispatch(setCurrentDomain(currentDomain, history));
  }

  function resetDomains() {
    dispatch(getDomains([]));
  }

  function handleSetCurrentType(currentType) {
    dispatch(
      setCurrentType({
        currentType,
      }),
    );
  }

  const handleSetCurrentSelectedType = (currentType) => {
    dispatch(setSelectedCurrentType(currentType));
    localStorage.setItem(primaryVariables.currentType, currentType);
  };

  useEffect(() => {
    dispatch(
      setSelectedCurrentType(
        localStorage.getItem(primaryVariables.currentType),
      ),
    );
  }, []);

  const handleResetState = () => {
    dispatch(resetState());
  };

  const handleToggleDataTypeModal = (visible) => {
    dispatch(toggleDataTypeModal(visible));
  };

  const setCurrentDataTypeList = (currentDataTypeList) => {
    dispatch(setDataTypeList(currentDataTypeList));
  };

  return {
    domains,
    currentDomain,
    handleGetDomains,
    handleSetCurrentDomain,
    resetDomains,
    user,
    handleSetCurrentType,
    currentNewsletter,
    currentNotification,
    currentSMS,
    currentWhatsapp,
    currentSelectedDataType,
    handleSetCurrentSelectedType,
    handleResetState,
    isTransactional: currentSelectedDataType === 'notification',
    isVisibleSetDataTypeModal,
    handleToggleDataTypeModal,
    setCurrentDataTypeList,
    currentDataTypeList,
  };
};
