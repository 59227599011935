import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import AuthService from 'services/Auth';
import Layout from 'components/molecules/layout';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = AuthService.getToken();
  const history = useHistory();

  return token ? (
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout {...routeProps} {...rest}>
          <Component {...rest} {...routeProps} />
        </Layout>
      )}
    ></Route>
  ) : (
    <Redirect to={`/sign-in?redirectUrl=${history.location.pathname}`} />
  );
};

export default ProtectedRoute;
