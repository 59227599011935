export const languages = {
  engKey: 'en',
  itKey: 'it',
};

export const primaryVariables = {
  emailKey: 'current_email',
  domainKey: 'current_company',
  currentNotification: 'current_notification',
  currentNewsletter: 'current_newsletter',
  currentSMS: 'current_sms',
  currentWhatsapp: 'current_whatsapp',
  currentType: 'current_type',
};
