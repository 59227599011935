import DomainApi from '../../../libs/api/domains';
import { useDomains } from '../../../redux/domain/use-domains';
import { useEffect, useState } from 'react';

const api = new DomainApi();

const useDomainAnalytics = () => {
  const {
    currentNewsletter,
    currentDomain,
    currentSelectedDataType,
    currentSMS,
    currentNotification,
  } = useDomains();
  const [contactsAnalytics, setContactsAnalytics] = useState({
    count: 0,
    limit: 0,
    percentage: 0,
  });

  const [domainAnalyticsData, setDomainAnalyticsData] = useState({
    day: {
      count: 0,
      limit: 0,
      percentage: 0,
      list: [],
    },
    month: {
      count: 0,
      limit: 0,
      percentage: 0,
      list: [],
    },
  });
  const [progressAnalytics, setProgressAnalytics] = useState({
    day_limit: {
      limit: 0,
      percentage: 0,
      used: 0,
    },
    month_limit: {
      limit: 0,
      percentage: 0,
      used: 0,
    },
  });
  const [domainAnalyticsLoading, setDomainAnalyticsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      switch (currentSelectedDataType) {
        case 'notification':
        case 'newsletter': {
          if (currentNewsletter?.id || currentNotification?.id) {
            setDomainAnalyticsLoading(true);
            let id = '';
            let domain_id = '';
            if (currentSelectedDataType === 'notification') {
              id = currentNotification?.id;
              domain_id = currentNotification?.domain_id;
            } else if (currentSelectedDataType === 'newsletter') {
              id = currentNewsletter?.id;
              domain_id = currentNewsletter?.domain_id;
            }

            try {
              const {
                data = {
                  day_limit: {
                    limit: 0,
                    percentage: 0,
                    used: 0,
                  },
                  month_limit: {
                    limit: 0,
                    percentage: 0,
                    used: 0,
                  },
                },
              } = await api.getProgressAnalyticsForDomainEmails(id, {
                domain_id: domain_id,
              });
              setProgressAnalytics({
                day_limit: {
                  limit: data.day_limit.limit || 0,
                  percentage: data.day_limit.percentage || 0,
                  used: data.day_limit.used || 0,
                },
                month_limit: {
                  limit: data.month_limit.limit || 0,
                  percentage: data.month_limit.percentage || 0,
                  used: data.month_limit.used || 0,
                },
              });
            } catch (e) {
              console.log(e);
            } finally {
              setDomainAnalyticsLoading(false);
            }
          }
          break;
        }
        case 'sms': {
          if (currentSMS?.id) {
            setDomainAnalyticsLoading(true);
            try {
              const { data } = await api.getProgressAnalyticsForSms(
                currentSMS.id,
              );

              setProgressAnalytics(data);
            } catch (e) {
              console.log(e);
            } finally {
              setDomainAnalyticsLoading(false);
            }
          }
          break;
        }
      }
    })();
  }, [
    currentSelectedDataType,
    currentNewsletter,
    currentNotification,
    currentSMS,
  ]);

  useEffect(() => {
    (async () => {
      if (currentDomain.id) {
        setDomainAnalyticsLoading(true);
        try {
          const {
            data: { data },
          } = await api.getDomainAnalytics(currentDomain.id);

          setDomainAnalyticsData(data);
        } catch (e) {
          console.log(e);
        } finally {
          setDomainAnalyticsLoading(false);
        }
      }
    })();
  }, [currentDomain?.id]);

  useEffect(() => {
    if (currentDomain?.id) {
      (async () => {
        try {
          const {
            data: { contacts_analytics },
          } = await api.getCompany(currentDomain?.id);
          setContactsAnalytics(contacts_analytics);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [currentDomain?.id]);

  return {
    domainAnalyticsData,
    domainAnalyticsLoading,
    contactsAnalytics,
    progressAnalytics,
  };
};

export default useDomainAnalytics;
