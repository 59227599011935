import React, { useEffect } from 'react';
import useDomainCharts from './useDomainCharts';
import { Col, Empty, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from 'recharts';
import './style.scss';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReportPdfHomePage = () => {
  let query = useQuery();
  localStorage.setItem('auth_token', query.get('token'));
  const { chartsData, label } = useDomainCharts(query);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(query.get('lang') || 'it');
    })();
  }, []);

  return (
    <div className={'domainChart'}>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Sent mail over time')} ({chartsData.total.send})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          {chartsData.sendData.length > 0 && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.sendData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine stroke='#000' />
                <Bar dataKey={label} fill='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          )}
          {chartsData.sendData.length === 0 && (
            <Row justify='center' align='middle'>
              <Empty description={false} />
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Open mail over time')} ({chartsData.total.opened})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          {chartsData.openedData.length > 0 && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.openedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={label} fill='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          )}
          {chartsData.openedData.length === 0 && (
            <Row justify='center' align='middle'>
              <Empty description={false} />
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Failed mail over time')} ({chartsData.total.failed})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          {chartsData.failedData.length > 0 && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.failedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={label} fill='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          )}
          {chartsData.failedData.length === 0 && (
            <Row justify='center' align='middle'>
              <Empty description={false} />
            </Row>
          )}
        </Col>
      </Row>
      <Row className={'chartColl'}>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Clicked links over time')} ({chartsData.total.clicked})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          {chartsData.clickedData.length > 0 && (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.clickedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={label} fill='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          )}
          {chartsData.clickedData.length === 0 && (
            <Row justify='center' align='middle'>
              <Empty description={false} />
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReportPdfHomePage;
