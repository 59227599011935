import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import store from 'redux/store';
import 'moment/locale/it';
import { ActionCableProvider } from 'react-actioncable-provider';
import { API_WS_ROOT } from './common/constants/ws';

XMLHttpRequest.prototype.open = (function (open) {
  return function (method, url, async) {
    open.apply(this, arguments);
    this.setRequestHeader('customHeader1', 'someValue');
    this.setRequestHeader('customHeader2', 'someOtherValue');
  };
})(XMLHttpRequest.prototype.open);

function App() {
  return (
    <Provider store={store}>
      <ActionCableProvider url={API_WS_ROOT}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ActionCableProvider>
    </Provider>
  );
}

export default App;
