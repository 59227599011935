import React from 'react';
import useDomainCharts from './useDomainCharts';
import { Col, DatePicker, Empty, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './style.scss';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
} from 'recharts';

const { RangePicker } = DatePicker;

const NewsletterChartsPage = (props) => {
  const {
    id = 20,
    date = '2022-03-16',
    title = 'title',
  } = props?.match?.params || {};
  const { chartsData, loading, dateRange, setDateRange, label } =
    useDomainCharts(id, date);
  const { t } = useTranslation();

  return (
    <div className={'domainChart'}>
      <Row gutter={16}>
        <Col>
          <RangePicker
            defaultValue={[
              moment(dateRange[0], 'DD/MM/YYYY'),
              moment(dateRange[1], 'DD/MM/YYYY'),
            ]}
            onChange={(date, dateString) => setDateRange(dateString)}
            format={'DD/MM/YYYY'}
          />
        </Col>
      </Row>
      <br />
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{t('Send')}</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.sendData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.sendData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />

                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.sendData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{t('Delivered')}</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.deliveredData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.deliveredData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.failData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{t('Read')}</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.readData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.readData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.failData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>{t('Failed')}</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.failData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.failData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.failData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default NewsletterChartsPage;
