import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SMSApi from 'libs/api/sms';
import { useDomains } from 'redux/domain/use-domains';
import WhatsappApi from '../../../libs/api/whatsapp';

const api = new WhatsappApi('whatsapp');

const getColorByStatus = (status) => {
  switch (status) {
    case 'send':
      return '#0e9800';
    case 'failed':
      return '#ea4e29';
    case 'pending':
      return '#1aa4cc';
    default:
      return '#000000';
  }
};

const useLogs = () => {
  const { t } = useTranslation();
  const { currentSMS, currentWhatsapp } = useDomains();
  const [loading, setLoading] = useState(false);
  const [logData, setLogDat] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('all');
  const [pagination, setPagination] = useState({
    current: 1,
    next: 2,
    per_page: 30,
    total_pages: 0,
  });
  const range = [
    moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
  ];
  const [dateRange, setDateRange] = useState(range);
  const columns = [
    {
      title: t('Send to'),
      dataIndex: 'send_to',
      render: (d) => (d ? `+${d}` : d),
    },
    // {
    //     title: t('analytics.table.title1'),
    //     dataIndex: 'date',
    //     render: (text) => {
    //         return <span>{moment(text).format('DD/MM/YYYY')}</span>;
    //     },
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return (
          <span className='status' style={{ color: getColorByStatus(text) }}>
            {t(text)}
          </span>
        );
      },
    },
    {
      title: t('Message'),
      dataIndex: 'message',

      render: (text) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: 'more',
            }}
            style={{ width: 320, margin: 0 }}
          >
            {text}
          </Typography.Paragraph>
        );
      },
    },
    // {
    //   title: t('analytics.table.title1'),
    //   dataIndex: 'date',
    //   render: (text) => {
    //     return <span>{moment.utc(text).format('DD/MM/YYYY HH:mm')}</span>;
    //   },
    // },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          page: pagination.current,
          whatsapp_service_id: currentWhatsapp.id,
          send_to: search,
        },
      };
      if (status !== 'all') {
        params.params.status = status;
      }

      const {
        data: {
          pagination: newPagination = {
            current: 1,
            next: 2,
            per_page: 30,
            total_pages: 0,
          },
          sms_transactionals,
        },
      } = await api.getLogs(params);
      setPagination(newPagination);
      setLogDat(sms_transactionals);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [dateRange, pagination.current, status, search, currentSMS]);

  return {
    columns,
    pagination,
    logData,
    setPagination,
    loading,
    status,
    setStatus,
    search,
    setSearch,
    dateRange,
    setDateRange,
  };
};

export default useLogs;
