import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useQueueSizes from './useQueueSizes';
import { Col, Row, Spin } from 'antd';
import React from 'react';

const QueueSizesSection = () => {
  const { t } = useTranslation();
  const { loading, data } = useQueueSizes();

  return (
    <div className={styles.card}>
      <Spin spinning={loading}>
        <p className={styles.title}>{t('Queue sizes')}</p>
        <br />
        <Row gutter={[16, 16]}>
          {data.map((queue, index) => (
            <Col span={24} key={index}>
              <p className={styles.subTitle}>{queue.domain.domain}</p>
              <div className={styles.dataSection}>
                <div className={styles.dataSection_dataContainer}>
                  <span className={styles.dataSection_title}>{t('Size')}</span>
                  <span>
                    <b>{queue.queue_size}</b>
                  </span>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <br />
      </Spin>
    </div>
  );
};

export default QueueSizesSection;
