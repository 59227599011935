import { combineReducers } from 'redux';

import userReducer from './user/reducer';
import domainReducer from './domain/reducer';
import domainEmails from './domain-emails/reducer';

export default combineReducers({
  user: userReducer,
  domains: domainReducer,
  emails: domainEmails
});
