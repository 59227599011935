import React, { useEffect } from 'react';
import useDomainCharts from './useDomainCharts';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
  LineChart,
  Line,
} from 'recharts';
import './style.scss';
import { useLocation } from 'react-router-dom';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReportPdfContactsPage = (props) => {
  let query = useQuery();
  localStorage.setItem('auth_token', query.get('token'));
  const { chartsData } = useDomainCharts(query);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(query.get('lang') || 'it');
    })();
  }, []);
  return (
    <div className={'domainChart'}>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Total Contacts')} ({chartsData.total.total_contacts})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <LineChart
              width={500}
              height={400}
              data={chartsData.totalContacts}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Line
                dataKey={t('Contatti')}
                fill='#8884d8'
                type='monotone'
                dot={''}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Unsubscribed Contacts')} (
            {chartsData.total.unsubscribed_contacts})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.unsubscribedContacts}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Auto Removed Contacts')} (
            {chartsData.total.auto_removed_contacts})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.autoRemovedContacts}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row className={'chartColl'}>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('New Contacts')} ({chartsData.total.new_contacts})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.newContacts}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Manually Imported Contacts')} (
            {chartsData.total.manually_imported_contacts})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.manuallyImportedContacts}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Imported Contacts from Wfi')} (
            {chartsData.total.imported_contacts_from_wfi})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.importedContactsFromWfi}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row className={'chartColl'}>
        <Col span={24}>
          <Typography.Title level={3}>
            {t('Imported Contacts from Website')} (
            {chartsData.total.imported_contacts_from_website})
          </Typography.Title>
        </Col>
        <Col
          span={16}
          style={{
            height: 400,
            width: '100%',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={400}
              data={chartsData.importedContactsFromWebsite}
              margin={{
                top: 5,
                right: 100,
                left: 20,
                bottom: 120,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={{
                  angle: -35,
                  textAnchor: 'end',
                  dominantBaseline: 'ideographic',
                }}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{ lineHeight: '40px' }}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey={t('Contatti')} fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </div>
  );
};

export default ReportPdfContactsPage;
