import * as types from './types';

export const setDomainEmails = (payload) => ({
  type: types.SET_DOMAINS_EMAIL,
  payload,
});

export const getDomainsRequest = (id) => ({
  type: types.GET_DOMAINS_EMAIL_REQUEST,
  payload: { id },
});

export const setCurrentDomainEmail = (payload) => ({
  type: types.SET_CURENT_EMAIL,
  payload,
});
