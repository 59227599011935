import React from 'react';
import { Col, Divider, Dropdown, Layout, Menu, Radio, Row } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const { Header } = Layout;

import styles from './style.module.scss';

const MyHeader = ({
  currentDomain = {},
  setIsVisibleSetDomainModal,
  langRadioBtn,
  onClickRadioBtn,
  handleClickProfileMenuItem,
  currentSelectedDataType,
  currentNewsletter,
  currentNotification,
  currentSMS,
  currentWhatsapp,
  onClickMenuItem,
  role,
}) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();
  let name = '';
  switch (currentSelectedDataType) {
    case 'newsletter': {
      name = currentNewsletter.email;
      break;
    }
    case 'notification': {
      name = currentNotification.email;
      break;
    }
    case 'sms': {
      name = currentSMS.name;
      break;
    }
    case 'whatsapp': {
      name = currentWhatsapp.name;
      break;
    }
  }
  return (
    <Header
      className='layout-header site-layout-background'
      style={{ padding: 0 }}
    >
      <div className='header-left-side'>
        <Row>
          <Col>
            <div className={styles.changDomainContainer}>
              <div
                className={styles.userBtn}
                onClick={() => {
                  setIsVisibleSetDomainModal(true);
                }}
              >
                {currentDomain?.name}
                <DownOutlined />
              </div>
            </div>
          </Col>
          {pathname !== '/' &&
            !pathname.includes('contacts') &&
            !pathname.includes('/config') && (
              <Col>
                <div className={styles.changDomainContainer}>
                  <div
                    className={styles.userBtn}
                    onClick={() => {
                      onClickMenuItem(currentSelectedDataType, true);
                    }}
                  >
                    {name}
                    <DownOutlined />
                  </div>
                </div>
              </Col>
            )}
        </Row>
      </div>
      <div className='header-right-side'>
        <Dropdown
          overlay={
            <Menu onClick={handleClickProfileMenuItem}>
              <Menu.Item key='changePassword'>
                {t('layout.changePassword')}
              </Menu.Item>
              {role !== 'whatsapp_operator' && (
                <>
                  <Menu.Item key='config'>{t('Config')}</Menu.Item>
                </>
              )}

              <Divider className={styles.divider} />
              <Menu.Item key='logout'>{t('base.logout')}</Menu.Item>
            </Menu>
          }
        >
          <div className={styles.dropdownTrigger}>
            <UserOutlined />
            {t('layout.profile')}
            <DownOutlined className={styles.arrow} />
          </div>
        </Dropdown>
        <Radio.Group
          value={langRadioBtn}
          className='choose-language'
          onChange={onClickRadioBtn}
        >
          <Radio.Button type='primary' value='itKey'>
            IT
          </Radio.Button>
          <Radio.Button value='engKey'>EN</Radio.Button>
        </Radio.Group>
      </div>
    </Header>
  );
};

export default MyHeader;
