import { Input } from 'antd';
import styles from './style.module.scss';

const MyInput = (props) => {
  const { className, ...rest } = props;
  return <Input className={styles.myTable + ` ${className}`} {...rest} />;
};

export const MyTextArea = (props) => {
  const { className, ...rest } = props;
  return (
    <Input.TextArea className={styles.myTable + ` ${className}`} {...rest} />
  );
};

export default MyInput;
