import { useDomains } from 'redux/domain/use-domains';
import { useEffect, useState } from 'react';
import DomainApi from 'libs/api/domains';
import { primaryVariables } from 'common/constants';
import ComposeApi from 'libs/api/compose';
import { useForm } from 'antd/es/form/Form';
import UIHelper from 'services/UIHelper';

const api = new DomainApi('companies/');
const apiTemplates = new ComposeApi();

const useConfig = () => {
  const [form] = useForm();
  const { currentDomain } = useDomains();
  const [mailTemplates, setMailTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFields([
      {
        name: 'auto_unreachable',
        value: currentDomain.auto_unreachable,
      },
      {
        name: 'contact_activity_month',
        value: currentDomain?.contact_activity_month,
      },
      {
        name: 'renewed_template',
        value: currentDomain?.renewed_template,
      },
      {
        name: 'is_send_birthday',
        value: currentDomain?.is_send_birthday,
      },
      {
        name: 'birthday_template',
        value: currentDomain?.birthday_template,
      },
      {
        name: 'qnt_auto_unreachable',
        value: currentDomain?.qnt_auto_unreachable,
      },
    ]);
  }, [currentDomain]);

  const handleSave = async (values) => {
    console.log('values=', values);
    try {
      setLoading(true);
      const {
        data: { company },
      } = await api.put(currentDomain.id, {
        data: {
          ...values,
          qnt_auto_unreachable: +values.qnt_auto_unreachable,
        },
      });
      localStorage.setItem(
        primaryVariables.domainKey,
        JSON.stringify({
          ...currentDomain,
          ...company,
        }),
      );
      UIHelper.openNotificationWithIcon(
        'success',
        'Sucesso',
        'L’operazione è stata completata con successo',
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTemplates = async () => {
    setLoading(true);
    try {
      const {
        data: { mail_templates },
      } = await apiTemplates.getTemplates({
        params: {},
      });
      setMailTemplates(mail_templates);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetTemplates();
    })();
  }, []);

  return {
    currentDomain,
    loading,
    mailTemplates,
    form,
    handleSave,
  };
};

export default useConfig;
