import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useDomainAnalytics from '../../../components/molecules/layout/useDomainAnalytics';
import { Col, Row, Spin, Typography } from 'antd';
import Helpers from '../../../helpers';
import ProgressBar from '@ramonak/react-progress-bar';
import cx from 'classnames';
import React from 'react';
import { useDomains } from '../../../redux/domain/use-domains';

const ProgressSection = () => {
  const { t } = useTranslation();
  const {
    domainAnalyticsData = {},
    domainAnalyticsLoading,
    contactsAnalytics,
  } = useDomainAnalytics();
  const { currentDomain } = useDomains();
  return (
    <div className={styles.card}>
      <Spin spinning={domainAnalyticsLoading}>
        <p className={styles.title}>{t('Panoramica consumi')}</p>
        <br />
        {domainAnalyticsData?.day?.list?.map((item, index) => {
          const monthItem = domainAnalyticsData?.month?.list[index];
          return (
            <React.Fragment key={index}>
              <p className={styles.subTitle}>{item.title}</p>
              <Row className={styles.progressContainer} gutter={[16, 16]}>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Typography.Text className={styles.statisticText}>
                        email / giorno
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className={styles.statisticText}>
                        {Helpers.numberWithCommas(item.used)} su{' '}
                        {Helpers.numberWithCommas(item?.limit)}{' '}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <ProgressBar
                    barContainerClassName={cx(
                      styles.barContainer,
                      item?.percentage?.toFixed(1) < 20 &&
                        'barContainerSmallValue',
                    )}
                    completed={Number(item?.percentage?.toFixed(1))}
                    bgColor={'#ff3600'}
                  />
                </Col>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Typography.Text className={styles.statisticText}>
                        email / mese
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className={styles.statisticText}>
                        {Helpers.numberWithCommas(monthItem.used)} su{' '}
                        {Helpers.numberWithCommas(monthItem.limit)}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <ProgressBar
                    barContainerClassName={cx(
                      styles.barContainer,
                      monthItem.percentage?.toFixed(1) < 20 &&
                        'barContainerSmallValue',
                    )}
                    completed={Number(monthItem.percentage?.toFixed(1))}
                    bgColor={'#383841'}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
        <Row className={styles.progressContainer} gutter={[16, 16]}>
          {currentDomain.sms && (
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  <Typography.Text className={styles.statisticText}>
                    contatti
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text className={styles.statisticText}>
                    {Helpers.numberWithCommas(contactsAnalytics?.count)} su{' '}
                    {Helpers.numberWithCommas(contactsAnalytics?.limit)}{' '}
                  </Typography.Text>
                </Col>
              </Row>
              <ProgressBar
                barContainerClassName={cx(
                  styles.barContainer,
                  contactsAnalytics?.percentage?.toFixed(1) < 20 &&
                    'barContainerSmallValue',
                )}
                completed={Number(contactsAnalytics?.percentage?.toFixed(1))}
                bgColor={'#383841'}
              />
            </Col>
          )}
        </Row>
      </Spin>
      {/*<Spin spinning={domainAnalyticsLoading}>*/}
      {/*  <Row className={'domainAnalyticsData'} gutter={[16, 16]}>*/}
      {/*    <Col span={24}>*/}
      {/*      <Typography.Text className={styles.statisticTextSMS}>*/}
      {/*        {currentDomain.is_customer && (*/}
      {/*          <>*/}
      {/*            {Helpers.numberWithCommas(currentDomain.sms_remaining)} sms*/}
      {/*            rimasti*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*        <br />*/}
      {/*        {!currentDomain.is_customer && (*/}
      {/*          <>*/}
      {/*            {Helpers.numberWithCommas(currentDomain.credits)} crediti*/}
      {/*            rimasti*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*      </Typography.Text>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Spin>*/}
    </div>
  );
};

export default ProgressSection;
