import React, { useState, useEffect, useCallback } from 'react';
import { Layout, ConfigProvider } from 'antd';
import './style.scss';
import UIHelper from 'services/UIHelper';
import logo from 'assets/images/main-pure-logo.png';
import { Link, useHistory } from 'react-router-dom';
import { useDomains } from 'redux/domain/use-domains';
import { useDomainsEmails } from 'redux/domain-emails/use-domains-emails';
import SetDomainModal from './domain-email-modal';
import ChangePasswordModal from './change-passwrod-modal';
import { useTranslation } from 'react-i18next';
import { languages, primaryVariables } from 'common/constants';
import AuthService from 'services/Auth';
import routesCodes from 'common/constants/route-codes';
import AuthApi from 'libs/api/auth';
import { useUser } from 'redux/user/use-user';

import localeEN from 'antd/lib/locale/en_US';
import localeIt from 'antd/lib/locale/it_IT';
import useDomainAnalytics from './useDomainAnalytics';
import NewMenu from './new-menu';
import MyHeader from './header';
import ChangeDataTypeModal from './changeDataType';
import useAuth from '../../../hooks/useAuth';

const { Content, Sider } = Layout;
const authApi = new AuthApi();

const SiderDemo = (props) => {
  useAuth();
  const {
    domainAnalyticsData,
    domainAnalyticsLoading,
    contactsAnalytics,
    progressAnalytics,
  } = useDomainAnalytics();

  const [isVisibleSetDomainModal, setIsVisibleSetDomainModal] = useState(false);
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const {
    domains,
    currentDomain,
    handleGetDomains,
    handleSetCurrentDomain,
    resetDomains,
    handleSetCurrentType,
    currentNewsletter,
    currentNotification,
    currentSMS,
    currentWhatsapp,
    currentSelectedDataType,
    handleSetCurrentSelectedType,
    user = {},
    handleResetState,
    isVisibleSetDataTypeModal,
    handleToggleDataTypeModal,
    setCurrentDataTypeList,
    currentDataTypeList,
  } = useDomains();
  const { handleSetLocalization } = useUser();
  const { role = '' } = user;

  const { emails, handleGetDomainEmails, resetMails } = useDomainsEmails();
  const [isChangePasModalVisible, setIsChangePasModalVisible] = useState(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const [modalChangeBtnLoading, setModalChangeBtnLoading] = useState(false);
  const [changePasswordErrors, setChangePasswordErrors] = useState([]);
  useEffect(() => {
    handleGetDomains();
  }, []);

  const handleLogout = useCallback(
    (fromHeader) => {
      AuthService.logout();
      resetDomains();
      resetMails();
      handleResetState();
      if (fromHeader) {
        history.push('/sign-in');
      } else {
        history.push(`/sign-in?redirectUrl=${history.location.pathname}`);
      }
    },
    [history],
  );

  const { t, i18n, ready } = useTranslation();
  const [langRadioBtn, setLangRadioBtn] = useState('itKey');
  const onSetCurentDomain = useCallback(
    (id) => {
      const domain = domains.find((item) => item.id === id);
      handleSetCurrentDomain(domain);
      if (domain) {
        localStorage.setItem(
          primaryVariables.domainKey,
          JSON.stringify(domain),
        );
      }
    },
    [domains],
  );

  useEffect(() => {
    if (emails[0]) {
    }
  }, [emails]);

  useEffect(() => {
    if (currentDomain.id) {
      handleGetDomainEmails(currentDomain.id);
    }
  }, [currentDomain]);

  useEffect(() => {
    const storedDomain = localStorage.getItem(primaryVariables.domainKey);
    const currentNewsletters = localStorage.getItem(
      primaryVariables.currentNewsletter,
    );
    const currentNotifications = localStorage.getItem(
      primaryVariables.currentNotification,
    );

    const currentSMS = localStorage.getItem(primaryVariables.currentSMS);
    const currentWhatsapp = localStorage.getItem(
      primaryVariables.currentWhatsapp,
    );

    if (storedDomain) {
      handleSetCurrentDomain(JSON.parse(storedDomain));
    }

    if (currentNewsletters) {
      handleSetCurrentType(JSON.parse(currentNewsletters));
    }
    if (currentNotifications) {
      handleSetCurrentType(JSON.parse(currentNotifications));
    }
    if (currentSMS) {
      handleSetCurrentType(JSON.parse(currentSMS));
    }
    if (currentWhatsapp) {
      handleSetCurrentType(JSON.parse(currentWhatsapp));
    }

    if (storedDomain) {
      setIsVisibleSetDomainModal(false);
    } else {
      if (domains.length === 1) {
        onSetCurentDomain(domains[0].id);
        setIsVisibleSetDomainModal(false);
      } else {
        if (domains.length > 1) {
          setIsVisibleSetDomainModal(true);
        }
      }
    }
  }, [domains]);

  const onClickRadioBtn = async (e) => {
    setLangRadioBtn(e.target.value);
    await i18n.changeLanguage(languages[e.target.value]);

    handleSetLocalization(languages[e.target.value]);
  };
  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(languages.itKey);
      handleSetLocalization(languages.itKey);
      // handleSetAntdLocalization(languages.antdLocalization[languages.itKey]);
    })();
  }, []);

  const handleChangePassword = useCallback(async (data, cb) => {
    setModalChangeBtnLoading(true);
    try {
      const response = await authApi.updatePassword(data);
      cb();
      setIsChangePasModalVisible(false);
      UIHelper.openNotificationWithIcon('success', t('layout.passwordChanged'));
    } catch (error) {
      const errors = error?.response?.data?.errors || [];
      if (errors.length) {
        setChangePasswordErrors(errors);
      } else {
        UIHelper.serverError();
      }
    } finally {
      setModalChangeBtnLoading(false);
    }
  }, []);

  const handleClickProfileMenuItem = useCallback((value) => {
    const { key } = value;
    if (key === 'changePassword') {
      setIsChangePasModalVisible(true);
    } else if (key === 'notifications') {
      setIsNotificationModalVisible(true);
    } else if (key === 'logout') {
      handleLogout(true);
    } else if (key === 'config') {
      history.push(routesCodes.config);
    }
  }, []);

  let isTransactional = currentDomain?.isNotifications;
  const { path } = props;
  if (path !== '/analytics' && isTransactional) {
    // return <Redirect to='/analytics'/>;
  }

  let locale = localeEN;
  if (i18n.language === 'it') {
    locale = localeIt;
  }

  const onClickMenuItem = useCallback(
    (value, fromHeader) => {
      handleSetCurrentSelectedType(value);
      switch (value) {
        case 'newsletter': {
          if (
            (currentDomain.newsletters.length > 1 && !currentNewsletter.id) ||
            fromHeader
          ) {
            setCurrentDataTypeList(
              currentDomain.newsletters.map((i) => ({
                ...i,
                name: i.email,
                type: 'newsletter',
              })),
            );
            handleToggleDataTypeModal(true);
          } else {
            if (!currentNewsletter.id) {
              const newsletter = currentDomain.newsletters[0];
              handleSetCurrentType({
                ...newsletter,
                type: 'newsletter',
              });
            }
          }
          break;
        }
        case 'notification': {
          if (
            (currentDomain.notifications.length > 1 &&
              !currentNotification.id) ||
            fromHeader
          ) {
            setCurrentDataTypeList(
              currentDomain.notifications.map((i) => ({
                ...i,
                name: i.email,
                type: 'notification',
              })),
            );
            handleToggleDataTypeModal(true);
          } else {
            if (!currentNotification.id) {
              const newsletter = currentDomain.notifications[0];
              handleSetCurrentType({
                ...newsletter,
                type: 'notification',
              });
            }
          }
          break;
        }
        case 'sms': {
          if ((currentDomain.sms.length > 1 && !currentSMS.id) || fromHeader) {
            setCurrentDataTypeList(
              currentDomain.sms.map((i) => ({
                ...i,
                name: i.title,
                type: 'sms',
              })),
            );
            handleToggleDataTypeModal(true);
          } else {
            if (!currentSMS.id) {
              const newsletter = currentDomain.sms[0];
              handleSetCurrentType({
                ...newsletter,
                type: 'sms',
              });
            }
          }
          break;
        }
        case 'whatsapp': {
          if (
            (currentDomain.whatsapp.length > 1 && !currentWhatsapp.id) ||
            fromHeader
          ) {
            setCurrentDataTypeList(
              currentDomain.whatsapp.map((i) => ({
                ...i,
                name: i.name,
                type: 'whatsapp',
              })),
            );
            handleToggleDataTypeModal(true);
          } else {
            if (!currentWhatsapp.id) {
              const newsletter = currentDomain.whatsapp[0];
              handleSetCurrentType({
                ...newsletter,
                type: 'whatsapp',
              });
            }
          }
          break;
        }
      }
    },
    [
      currentDomain,
      currentNotification,
      currentNewsletter,
      currentSMS,
      currentWhatsapp,
    ],
  );

  const onSetCurrentType = (type) => {
    handleSetCurrentType(type);
  };

  return ready ? (
    <>
      <ConfigProvider locale={locale}>
        <Layout className='main-layout' style={{ minHeight: '100vh' }}>
          <Sider
            width={266}
            className='side-bar'
            collapsed={false}
            onCollapse={() => {
              setCollapsed(!collapsed);
            }}
          >
            <div className='side-bar-logo'>
              <Link to='/'>
                <div className='logo-background'>
                  <img className='logo' src={logo} />
                </div>
              </Link>
            </div>
            <NewMenu
              contactsAnalytics={contactsAnalytics}
              domainAnalyticsLoading={domainAnalyticsLoading}
              domainAnalyticsData={domainAnalyticsData}
              currentDomain={currentDomain}
              onClickMenuItem={onClickMenuItem}
              progressAnalytics={progressAnalytics}
              role={role}
            />
          </Sider>
          <Layout className='site-layout'>
            <MyHeader
              role={role}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              currentDomain={currentDomain}
              setIsVisibleSetDomainModal={setIsVisibleSetDomainModal}
              handleLogout={handleLogout}
              langRadioBtn={langRadioBtn}
              onClickRadioBtn={onClickRadioBtn}
              handleClickProfileMenuItem={handleClickProfileMenuItem}
              currentNewsletter={currentNewsletter}
              currentNotification={currentNotification}
              currentSMS={currentSMS}
              currentWhatsapp={currentWhatsapp}
              currentSelectedDataType={currentSelectedDataType}
              onClickMenuItem={onClickMenuItem}
              // t={t}
            />

            <Content className='content' style={{ margin: '0 16px' }}>
              <div className='page' style={{ padding: 24, minHeight: 360 }}>
                {props.children}
              </div>
            </Content>
          </Layout>

          {isVisibleSetDataTypeModal && (
            <ChangeDataTypeModal
              currentDomain={currentDomain}
              domains={currentDataTypeList}
              onSetCurrentType={onSetCurrentType}
              setIsModalVisible={handleToggleDataTypeModal}
              isModalVisible={isVisibleSetDataTypeModal}
              currentNewsletter={currentNewsletter}
              currentNotification={currentNotification}
              currentSMS={currentSMS}
              currentWhatsapp={currentWhatsapp}
              currentSelectedDataType={currentSelectedDataType}
            />
          )}
          {isVisibleSetDomainModal && (
            <SetDomainModal
              currentDomain={currentDomain}
              domains={domains}
              setDomain={onSetCurentDomain}
              setIsModalVisible={setIsVisibleSetDomainModal}
              isModalVisible={isVisibleSetDomainModal}
            />
          )}

          <ChangePasswordModal
            t={t}
            onClickChange={handleChangePassword}
            isModalVisible={isChangePasModalVisible}
            setIsModalVisible={setIsChangePasModalVisible}
            changeBtnLoading={modalChangeBtnLoading}
            backendErrors={changePasswordErrors}
            setBackendErrors={setChangePasswordErrors}
          />
        </Layout>
      </ConfigProvider>
    </>
  ) : null;
};

export default SiderDemo;
