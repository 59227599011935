import * as types from './types';

export const getDomains = (payload) => ({
  type: types.GET_DOMAINS,
  payload,
});

export const getDomainsRequest = () => ({
  type: types.GET_DOMAINS_REQUEST,
});

export const setCurrentDomain = (payload, history) => ({
  type: types.SET_CURENT_DOMAIN,
  payload,
  history,
});

export const setCurrentType = (payload) => ({
  type: types.SET_CURENT_TYPE,
  payload,
});

export const setSelectedCurrentType = (payload) => ({
  type: types.SET_CURENT_SELECTED_TYPE,
  payload,
});

export const setDataTypeList = (payload) => ({
  type: types.SET_DATA_TYPE_LIST,
  payload,
});

export const resetState = () => ({
  type: types.RESET_STATE,
});

export const toggleDataTypeModal = (visible) => ({
  type: types.TOGLE_DATA_TYPE_MODAL,
  payload: visible,
});
