import Api from 'libs/api';

class TransactionalsApi extends Api {
  constructor() {
    super('');
  }

  getNewsletterChartAnalytics(params) {
    return this.buildQuery(
      'get',
      { ...params },
      'transactionals/fetch_chart_analytics',
    );
  }
}

export default TransactionalsApi;
