import React, { useCallback, useEffect, useState } from 'react';
import AnalyticsApi from 'libs/api/analytics';
import { notification } from 'antd';
import UIHelper from 'services/UIHelper';
import moment from 'moment';
import { getErrorByKey } from 'utils';
import Route from 'react-router-dom/es/Route';
import { useDomains } from 'redux/domain/use-domains';
import ConfigLogsPage from 'pages/ConfigLogsPage';

const api = new AnalyticsApi();

const ConfigLogsContainer = (props) => {
  const params = new URLSearchParams(props.location.search);
  const {
    path,
    match: {
      params: { type },
    },
  } = props;
  const [total, setTotal] = useState(0);
  const date = [
    moment(new Date(), 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
  ];
  const [modalLoadingBtn, setModalLoadingBtn] = useState(false);
  const { currentNewsletter, currentSelectedDataType, currentNotification } =
    useDomains();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [dateRange, setDateRange] = useState(date);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
  });

  const handleGetAnalytics = useCallback(async () => {
    setModalLoadingBtn(true);
    try {
      const queryParams = {
        date_from: dateRange[0],
        date_to: dateRange[1],
        page: pagination.page,
        per_page: pagination.per_page,
        order: 'DESC',
        custom_id: type,
      };
      const {
        data: { transactional, transactional_count },
      } = await api.getAnalytics(queryParams);
      setAnalyticsData(transactional);
      setTotal(transactional_count);
    } catch (error) {
      console.log(error);
      const baseError = getErrorByKey(error?.response?.data?.errors, 'base');
      notification.error({
        message: baseError || 'Oops! please try later',
      });
    } finally {
      setModalLoadingBtn(false);
    }
  }, [currentNewsletter, dateRange, pagination]);

  useEffect(async () => {
    await handleGetAnalytics();
  }, [dateRange, pagination, type]);

  const onChangePagination = useCallback((page, per_page) => {
    setPagination({
      page,
      per_page,
    });
  }, []);

  const handleDownload = async () => {
    try {
      const response = await api.getAnalyticsToDownload({
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Rome',
        },
        props: {
          responseType: 'blob',
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'analytics.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Download complete remove element
      window.URL.revokeObjectURL(url); // Release blob object
      UIHelper.openNotificationWithIcon('success', 'File downloaded.');
    } catch (error) {}
  };

  return (
    <>
      <Route path={path} exact>
        <ConfigLogsPage
          setDateRange={(range) => setDateRange(range)}
          modalLoadingBtn={modalLoadingBtn}
          analyticsData={analyticsData}
          pagination={pagination}
          setPagination={onChangePagination}
          dateRange={dateRange}
          total={total}
          handleDownload={handleDownload}
        />
      </Route>
    </>
  );
};

export default ConfigLogsContainer;
