import { all } from 'redux-saga/effects';

import userSagas from './user/sagas';
import domainSagas from './domain/sagas';
import domainEmailsSagas from './domain-emails/sagas';

export default function* rootSaga() {
  yield all([
    userSagas(),
    domainSagas(),
    domainEmailsSagas()
  ]);
}
