import { Col, Menu, Row, Spin, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiBookContent, BiMessageRounded } from 'react-icons/bi';
import {
  AiOutlineWhatsApp,
  AiOutlineFieldTime,
  AiOutlineArrowLeft,
  AiOutlineDoubleLeft,
  AiOutlineBarChart,
} from 'react-icons/ai';

import './style.scss';
import ProgressBar from '@ramonak/react-progress-bar';
import cx from 'classnames';
import Helpers from '../../../../helpers';
import uuid from 'react-uuid';
import { UsergroupAddOutlined } from '@ant-design/icons';

const menuKeys = {
  newsletter: 'newsletter',
  sms: 'sms',
  whatsapp: 'whatsapp',
  transactions: 'notification',
  contacts: 'contacts',
};

const subMenuItemKeys = {
  createEmail: 'createEmail',
  importHtml: 'importHtml',
  selectTemplate: 'selectTemplate',
  analytics: 'analytics',
  calendar: 'calendar',
  createSMSTemplate: 'createSMSTemplate',
  templateSMS: 'templateSMS',
  smsLogs: 'smsLogs',
  analyticsSMS: 'analyticsSMS',
  createWhatsAppTemplate: 'createWhatsAppTemplate',
  whatsAppTemplate: 'whatsAppTemplate',
  whatsAppConfig: 'whatsAppConfig',
  whatsApp: 'whatsApp',
  contactsTable: 'contactsTable',
  contactsImport: 'contactsImport',
  contactsGroups: 'contactsGroups',
  sendMail: 'sendMail',
  sendMailWhatsapp: 'sendMailWhatsapp',
  statisticsContacts: 'statisticsContacts',
  transactionsCharts: 'transactionsCharts',
  smsCharts: 'smsCharts',
  newsletterCharts: 'newsletterCharts',
  transactionalAnalytics: 'transactionalAnalytics',
  notifications: 'notifications',
  whatsappLogs: 'whatsappLogs',
  whatsappAnalytics: 'whatsappAnalytics',
  emailConfig: 'emailConfig',
  importHistory: 'importHistory',
};

const submenuItems = {
  [menuKeys.contacts]: [
    {
      key: subMenuItemKeys.contactsTable,
      title: 'Elenco',
      path: '/contacts/table',
    },
    {
      key: subMenuItemKeys.importHtml,
      title: 'Importa',
      path: '/contacts/import',
    },
    {
      key: subMenuItemKeys.importHistory,
      title: 'Storico import',
      path: '/contacts/imports-table',
    },
    {
      key: subMenuItemKeys.contactsGroups,
      title: 'Gruppi',
      path: '/contacts/groups',
    },
    {
      key: subMenuItemKeys.statisticsContacts,
      title: 'Statistiche contatti',
      path: '/contacts/charts',
    },
    {
      key: subMenuItemKeys.notifications,
      title: 'Notifiche',
      path: '/notifications',
    },
  ],
  [menuKeys.newsletter]: [
    {
      key: subMenuItemKeys.sendMail,
      title: 'Invia Newsletter',
      path: '/mail',
    },
    {
      key: subMenuItemKeys.createEmail,
      title: 'Componi con wizard',
      path: '/compose/wizard',
    },
    {
      key: subMenuItemKeys.importHtml,
      title: 'Componi importa HTML',
      path: '/compose/import-code',
    },
    {
      key: subMenuItemKeys.selectTemplate,
      title: 'Seleziona il template',
      path: '/compose/template',
    },
    {
      key: subMenuItemKeys.analytics,
      title: 'Analytics',
      path: '/analytics',
    },
    {
      key: subMenuItemKeys.calendar,
      title: 'Calendario',
      path: '/calendar',
    },
    {
      key: subMenuItemKeys.notifications,
      title: 'Notifiche',
      path: '/notifications',
    },
    {
      key: subMenuItemKeys.emailConfig,
      title: 'Config',
      path: '/email-config',
    },
    // {
    //   key: subMenuItemKeys.newsletterCharts,
    //   title: 'Grafici',
    //   path: '/charts',
    // },
  ],
  [menuKeys.sms]: [
    {
      key: subMenuItemKeys.createSMSTemplate,
      title: 'Crea Template SMS',
      path: '/sms/import-code',
    },
    {
      key: subMenuItemKeys.templateSMS,
      title: 'Template SMS',
      path: '/sms/template',
    },
    {
      key: subMenuItemKeys.smsLogs,
      title: 'Logs',
      path: '/sms/logs',
    },
    {
      key: subMenuItemKeys.analyticsSMS,
      title: 'Analytics',
      path: '/sms/analytics',
    },
    // {
    //   key: subMenuItemKeys.smsCharts,
    //   title: 'Grafici',
    //   path: '/charts',
    // },
  ],
  [menuKeys.whatsapp]: [
    {
      key: subMenuItemKeys.sendMailWhatsapp,
      title: 'Invia Newsletter',
      path: '/send',
    },
    {
      key: subMenuItemKeys.whatsApp,
      title: 'WhatsApp',
      path: '/whatsapp',
    },
    {
      key: subMenuItemKeys.createWhatsAppTemplate,
      title: 'Crea Template WhatsApp',
      path: '/whatsapp-template/import-code',
    },
    {
      key: subMenuItemKeys.whatsAppTemplate,
      title: 'Template WhatsApp',
      path: '/whatsapp-template/template',
    },
    {
      key: subMenuItemKeys.whatsappLogs,
      title: 'Logs',
      path: '/whatsapp-logs',
    },
    {
      key: subMenuItemKeys.whatsappAnalytics,
      title: 'Analytics',
      path: '/whatsapp-analytics',
    },
    {
      key: subMenuItemKeys.whatsAppConfig,
      title: 'Configurazione Whatsapp',
      path: '/whatsapp-config',
    },
  ],
  [menuKeys.transactions]: [
    {
      key: subMenuItemKeys.createEmail,
      title: 'Componi con wizard',
      path: '/transactional/compose/wizard',
    },
    {
      key: subMenuItemKeys.importHtml,
      title: 'Componi importa HTML',
      path: '/compose/import-code',
    },
    {
      key: subMenuItemKeys.selectTemplate,
      title: 'Seleziona il template',
      path: '/compose/template',
    },
    {
      key: subMenuItemKeys.analytics,
      title: 'Logs',
      path: '/analytics',
    },
    {
      key: subMenuItemKeys.transactionalAnalytics,
      title: 'Analytics',
      path: '/transactional-analytics',
    },
    {
      key: subMenuItemKeys.notifications,
      title: 'Notifiche',
      path: '/notifications',
    },
    {
      key: subMenuItemKeys.emailConfig,
      title: 'Config',
      path: '/email-config',
    },
    // {
    //   key: subMenuItemKeys.calendar,
    //   title: 'Calendario',
    //   path: '/calendar',
    // },
    // {
    //   key: subMenuItemKeys.transactionsCharts,
    //   title: 'Grafici',
    //   path: '/charts',
    // },
  ],
};

const SubMenu = ({
  selected,
  currentDomain,
  domainAnalyticsLoading,
  setMenuSelectedItemMain,
  contactsAnalytics,
  progressAnalytics,
  role,
}) => {
  const { t } = useTranslation();
  const [menuSelectedItem, setMenuSelectedItem] = useState(null);

  const items = useMemo(() => {
    switch (selected) {
      case menuKeys.contacts: {
        const currentSubItems = submenuItems[menuKeys.contacts];
        return (
          <React.Fragment key={uuid()}>
            <Menu.Item
              onClick={() => setMenuSelectedItemMain(null)}
              key={uuid()}
              icon={<AiOutlineArrowLeft height='2em' width='2em' />}
            >
              <span className='menu-item-title'>{t('home.contact')}</span>
            </Menu.Item>
            {currentSubItems.map((i) => (
              <Menu.Item key={i.key}>
                <span className='menu-item-title'>
                  <Link to={i.path}>{t(i.title)}</Link>
                </span>
              </Menu.Item>
            ))}
          </React.Fragment>
        );
      }
      case menuKeys.newsletter: {
        const currentSubItems = submenuItems[menuKeys.newsletter];
        return (
          <React.Fragment key={uuid()}>
            <Menu.Item
              onClick={() => setMenuSelectedItemMain(null)}
              key={uuid()}
              icon={<AiOutlineArrowLeft height='2em' width='2em' />}
            >
              <span className='menu-item-title'>{t('layout.newsletter')}</span>
            </Menu.Item>
            {currentSubItems.map((i) => (
              <Menu.Item key={i.key}>
                <span className='menu-item-title'>
                  <Link to={i.path}>{t(i.title)}</Link>
                </span>
              </Menu.Item>
            ))}
          </React.Fragment>
        );
      }
      case menuKeys.transactions: {
        const currentSubItems = submenuItems[menuKeys.transactions];
        return (
          <React.Fragment key={uuid()}>
            <Menu.Item
              onClick={() => setMenuSelectedItemMain(null)}
              key={uuid()}
              icon={<AiOutlineArrowLeft height='2em' width='2em' />}
            >
              <span className='menu-item-title'>{t('Transazionali')}</span>
            </Menu.Item>
            {currentSubItems.map((i) => (
              <Menu.Item key={i.key}>
                <span className='menu-item-title'>
                  <Link to={i.path}>{t(i.title)}</Link>
                </span>
              </Menu.Item>
            ))}
          </React.Fragment>
        );
      }
      case menuKeys.sms: {
        const currentSubItems = submenuItems[menuKeys.sms];
        return (
          <React.Fragment key={uuid()}>
            <Menu.Item
              onClick={() => setMenuSelectedItemMain(null)}
              key={uuid()}
              icon={<AiOutlineArrowLeft height='2em' width='2em' />}
            >
              <span className='menu-item-title'>{t('SMS')}</span>
            </Menu.Item>
            {currentSubItems.map((i) => (
              <Menu.Item key={i.key}>
                <span className='menu-item-title'>
                  <Link to={i.path}>{t(i.title)}</Link>
                </span>
              </Menu.Item>
            ))}
          </React.Fragment>
        );
      }
      case menuKeys.whatsapp: {
        let currentSubItems = submenuItems[menuKeys.whatsapp];
        console.log('currentSubItems=', currentSubItems);
        if (role === 'whatsapp_operator') {
          currentSubItems = currentSubItems.filter(
            (i) => i.key === subMenuItemKeys.whatsApp,
          );
        }
        return (
          <React.Fragment key={uuid()}>
            <Menu.Item
              key={uuid()}
              onClick={() => setMenuSelectedItemMain(null)}
              icon={<AiOutlineArrowLeft height='2em' width='2em' />}
            >
              <span className='menu-item-title'>{t('WhatsApp')}</span>
            </Menu.Item>
            {currentSubItems.map((i) => (
              <Menu.Item key={i.key}>
                <span className='menu-item-title'>
                  <Link to={i.path}>{t(i.title)}</Link>
                </span>
              </Menu.Item>
            ))}
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  }, [selected, t]);
  return (
    <div className='submenu-wrapper'>
      <Menu
        className='submenu'
        // defaultSelectedKeys={['1']}
        selectedKeys={menuSelectedItem}
        mode='inline'
        onSelect={(value) => {
          setMenuSelectedItem(value.selectedKeys);
        }}
      >
        {items}
      </Menu>
      {(selected === 'newsletter' ||
        selected === 'sms' ||
        selected === 'notification' ||
        selected === 'contacts') && (
        <Spin spinning={domainAnalyticsLoading}>
          <Row className={'progressAnalytics'} gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[10, 0]} align='middle'>
                <Col>
                  <AiOutlineBarChart size={20} color='#ffffff' />
                </Col>
                <Col>
                  <Typography.Text className='menu-progress-title'>
                    {t('Panoramica')}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            {(selected === 'newsletter' ||
              selected === 'notification' ||
              selected === 'sms') && (
              <>
                <Col span={20}>
                  <Row justify='space-between'>
                    <Col>
                      <Typography.Text className={'statisticText'}>
                        {selected === 'sms' ? 'sms' : 'email'} / giorno
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className={'statisticText'}>
                        {Helpers.numberWithCommas(
                          progressAnalytics.day_limit.used,
                        )}{' '}
                        su{' '}
                        {Helpers.numberWithCommas(
                          progressAnalytics.day_limit.limit,
                        )}{' '}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <ProgressBar
                    barContainerClassName={cx(
                      'barContainer',
                      progressAnalytics.day_limit.percentage?.toFixed(1) < 20 &&
                        'barContainerSmallValue',
                    )}
                    completed={Number(
                      progressAnalytics.day_limit.percentage?.toFixed(1),
                    )}
                    bgColor={'#ea4e29'}
                  />
                </Col>
                <Col span={20}>
                  <Row justify='space-between'>
                    <Col>
                      <Typography.Text className={'statisticText'}>
                        {selected === 'sms' ? 'sms' : 'email'} / mese
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className={'statisticText'}>
                        {Helpers.numberWithCommas(
                          progressAnalytics.month_limit.used,
                        )}{' '}
                        su{' '}
                        {Helpers.numberWithCommas(
                          progressAnalytics.month_limit.limit,
                        )}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <ProgressBar
                    barContainerClassName={cx(
                      'barContainer',
                      progressAnalytics.month_limit.percentage?.toFixed(1) <
                        20 && 'barContainerSmallValue',
                    )}
                    completed={Number(
                      progressAnalytics.month_limit.percentage?.toFixed(1),
                    )}
                    bgColor={'#ea4e29'}
                  />
                </Col>
              </>
            )}

            {selected === 'contacts' && (
              <Col span={20}>
                <Row justify='space-between'>
                  <Col>
                    <Typography.Text className={'statisticText'}>
                      contatti
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text className={'statisticText'}>
                      {Helpers.numberWithCommas(contactsAnalytics?.count)} su{' '}
                      {Helpers.numberWithCommas(contactsAnalytics?.limit)}{' '}
                    </Typography.Text>
                  </Col>
                </Row>
                <ProgressBar
                  barContainerClassName={cx(
                    'barContainer',
                    contactsAnalytics?.percentage?.toFixed(1) < 20 &&
                      'barContainerSmallValue',
                  )}
                  completed={Number(contactsAnalytics?.percentage?.toFixed(1))}
                  bgColor={'#ea4e29'}
                />
              </Col>
            )}
          </Row>
        </Spin>
      )}
      {currentDomain.isSms && (
        <Spin spinning={domainAnalyticsLoading}>
          <Row className={'progressAnalytics'} gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Text className={'statisticTextSMS'}>
                {currentDomain.is_customer && (
                  <>
                    {Helpers.numberWithCommas(currentDomain.sms_remaining)} sms
                    rimasti
                  </>
                )}
                <br />
                {!currentDomain.is_customer && (
                  <>
                    {Helpers.numberWithCommas(currentDomain.credits)} crediti
                    rimasti
                  </>
                )}
              </Typography.Text>
            </Col>
          </Row>
        </Spin>
      )}
    </div>
  );
};

const NewMenu = ({
  currentDomain,
  domainAnalyticsLoading,
  onClickMenuItem,
  contactsAnalytics,
  progressAnalytics,
  role,
}) => {
  const { t } = useTranslation();
  const [menuSelectedItem, setMenuSelectedItem] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const showSubmenu = useMemo(() => {
    return menuSelectedItem?.length;
  }, [menuSelectedItem]);

  const menuItems = useMemo(() => {
    const color = showSubmenu ? '#73737c' : '#ffffff';
    const [key] = menuSelectedItem || [];
    return (
      <>
        {currentDomain?.newsletters?.length > 0 && (
          <Menu.Item
            key={menuKeys.newsletter}
            icon={
              <BiBookContent
                height='2em'
                width='2em'
                color={key !== menuKeys.newsletter ? color : '#ffffff'}
              />
            }
          >
            <span className='menu-item-title'>
              <Link to='#'>{!showSubmenu && t('layout.newsletter')}</Link>
            </span>
          </Menu.Item>
        )}
        {currentDomain.sms?.length > 0 && (
          <Menu.Item
            key={menuKeys.sms}
            icon={
              <BiMessageRounded
                height='2em'
                width='2em'
                color={key !== menuKeys.sms ? color : '#ffffff'}
              />
            }
          >
            <span className='menu-item-title'>
              <Link to='#'>{!showSubmenu && t('layout.sms')}</Link>
            </span>
          </Menu.Item>
        )}
        {currentDomain.whatsapp?.length > 0 && (
          <Menu.Item
            key={menuKeys.whatsapp}
            icon={
              <AiOutlineWhatsApp
                height='2em'
                width='2em'
                color={key !== menuKeys.whatsapp ? color : '#ffffff'}
              />
            }
          >
            <span className='menu-item-title'>
              <Link to='#'>{!showSubmenu && t('layout.whatsapp')}</Link>
            </span>
          </Menu.Item>
        )}

        {currentDomain.notifications?.length > 0 && (
          <Menu.Item
            key={menuKeys.transactions}
            icon={
              <AiOutlineFieldTime
                height='2em'
                width='2em'
                color={key !== menuKeys.transactions ? color : '#ffffff'}
              />
            }
          >
            <span className='menu-item-title'>
              <Link to='#'>{!showSubmenu && t('layout.transactions')}</Link>
            </span>
          </Menu.Item>
        )}
        {role !== 'whatsapp_operator' && (
          <Menu.Item
            key={menuKeys.contacts}
            icon={
              <UsergroupAddOutlined
                height='2em'
                width='2em'
                color={key !== menuKeys.contacts ? color : '#ffffff'}
              />
            }
          >
            <span className='menu-item-title'>
              <Link to='#'>{!showSubmenu && t('Contatti')}</Link>
            </span>
          </Menu.Item>
        )}
      </>
    );
  }, [showSubmenu, menuSelectedItem, currentDomain, role]);

  return (
    <>
      <p className='menu-title'>{!collapsed && 'Rimail tools'}</p>
      <div className='new-left-menu-wrapper'>
        <Menu
          className={cx({
            'new-left-menu': true,
            'new-left-menu_closed': showSubmenu,
          })}
          selectedKeys={menuSelectedItem}
          mode='inline'
          onSelect={(value) => {
            onClickMenuItem(value.key);
            setMenuSelectedItem(value.selectedKeys);
          }}
        >
          {menuItems}
        </Menu>
        {showSubmenu && (
          <SubMenu
            role={role}
            currentDomain={currentDomain}
            progressAnalytics={progressAnalytics}
            domainAnalyticsLoading={domainAnalyticsLoading}
            selected={menuSelectedItem[0]}
            setMenuSelectedItemMain={setMenuSelectedItem}
            contactsAnalytics={contactsAnalytics}
            progressAnalytics={progressAnalytics}
          />
        )}
      </div>
      <div className='new-left-menu-wrapper_footer'>
        {/*<span>{t('Lorem ipsum')}</span>*/}
      </div>
      {menuSelectedItem && (
        <div
          role='presentation'
          onClick={() => setMenuSelectedItem(null)}
          className={cx({
            'menu-arrow-left': true,
            'menu-arrow-left_opened': showSubmenu,
          })}
        >
          <AiOutlineDoubleLeft color='#cfcfcf' />
        </div>
      )}
    </>
  );
};

export default NewMenu;
