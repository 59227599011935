import { primaryVariables } from 'common/constants';

export const tokenKey = 'auth_token';

class AuthService {
  static getToken = () => {
    return localStorage.getItem(tokenKey);
  };

  static setToken = (token) => {
    localStorage.setItem(tokenKey, token);
  };

  static logout = () => {
    localStorage.clear();
  };
}

export default AuthService;
