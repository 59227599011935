import React from 'react';
import useDomainCharts from './useDomainCharts';
import { Col, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Tag } from 'antd';
import './style.scss';

const TransactionalChartPage = (props) => {
  const { chartsData, loading } = useDomainCharts();
  const { t } = useTranslation();

  const calculateChartWidth = (dataLength) => {
    let width = dataLength * 135;
    const defaultWidth = window.innerWidth - 266 - 24 - 60;
    if (width < defaultWidth) {
      width = defaultWidth;
    }

    return width;
  };

  const isEmptyChart = (data) => {
    return !data[0]?.isEmpty;
  };

  return (
    <div className={'domainChart'}>
      <br />
      <Spin spinning={loading}>
        <Row gutter={16}>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Typography.Title level={3}>{t('Today')}</Typography.Title>
              </Col>
              <Col span={24}>
                <PieChart width={400} height={300}>
                  <Pie
                    data={chartsData.todayData}
                    // cx={120}
                    // cy={200}
                    innerRadius={80}
                    outerRadius={100}
                    fill='#8884d8'
                    paddingAngle={5}
                    dataKey='value'
                    label={isEmptyChart(chartsData.todayData)}
                  >
                    {chartsData.todayData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  {isEmptyChart(chartsData.todayData) && <Tooltip />}
                </PieChart>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Typography.Title level={3}>{t('Yesterday')}</Typography.Title>
              </Col>
              <Col span={24}>
                <PieChart width={400} height={300}>
                  <Pie
                    data={chartsData.yesterdayData}
                    // cx={120}
                    // cy={200}
                    innerRadius={80}
                    outerRadius={100}
                    fill='#8884d8'
                    paddingAngle={5}
                    dataKey='value'
                    label={isEmptyChart(chartsData.yesterdayData)}
                  >
                    {chartsData.yesterdayData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  {isEmptyChart(chartsData.todayData) && <Tooltip />}
                </PieChart>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Typography.Title level={3}>{t('Month')}</Typography.Title>
              </Col>
              <Col span={24}>
                <PieChart width={400} height={300}>
                  <Pie
                    data={chartsData.monthData}
                    // cx={120}
                    // cy={200}
                    innerRadius={80}
                    outerRadius={100}
                    fill='#8884d8'
                    paddingAngle={5}
                    dataKey='value'
                    label
                  >
                    {chartsData.monthData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <Row align={'center'}>
        <Tag color='#4a4a4a'>
          <Typography.Title
            level={4}
            style={{
              marginBottom: 0,
              color: '#fff',
            }}
          >
            {t('Nessun Messaggio')}
          </Typography.Title>
        </Tag>
        <Tag color='#00C49F'>
          <Typography.Title
            level={4}
            style={{
              marginBottom: 0,
              color: '#fff',
            }}
          >
            {t('Delivered')}
          </Typography.Title>
        </Tag>
        <Tag color='#FFBB28'>
          <Typography.Title
            level={4}
            style={{
              marginBottom: 0,
              color: '#fff',
            }}
          >
            {t('Pending')}
          </Typography.Title>
        </Tag>
        <Tag color='#FF8042'>
          <Typography.Title
            level={4}
            style={{
              marginBottom: 0,
              color: '#fff',
            }}
          >
            {t('Failed')}
          </Typography.Title>
        </Tag>
      </Row>
    </div>
  );
};

export default TransactionalChartPage;
