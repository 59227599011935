import axios from 'axios';
import Qs from 'qs';
import AuthService from '../services/Auth';

export default function configureAxios() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 422) {
        // UIHelper.showRequestErrorToast();
      }
      if (error?.response?.status === 404) {
        // UIHelper.showNotFoundErrorToast();
      }
      if (error?.response?.status === 413) {
        // UIHelper.showServerConfigError();
      }
      if (error?.response?.status === 401) {
    
        AuthService.logout();
      }
      return Promise.reject(error);
    },
  );
  axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      // Qs is already included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      });
    };

    return config;
  });
}
