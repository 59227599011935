const prefix = '/';

const routesCodes = {
  home: prefix,
  signIn: `${prefix}sign-in`,
  forgetPassword: `${prefix}forget-password`,
  recoverPassword: `${prefix}password-recover`,
  compose: `${prefix}compose`,
  composeTransactional: `${prefix}transactional/compose`,
  contacts: `${prefix}contacts`,
  analytics: `${prefix}analytics`,
  mail: `${prefix}mail`,
  somePage: `${prefix}some-page`,
  unsubscribe: `${prefix}unsubscribe/:token`,
  unsubscribeSuccess: `${prefix}unsubscribe/success`,
  abuse: `${prefix}abuse`,
  calendar: `${prefix}calendar`,
  privacy: `${prefix}privacy_policy/:token`,
  privacyWrong: `${prefix}privacy-policy/:token`,
  confirmSubscription: `${prefix}confirm_subscription`,
  sms: `${prefix}sms`,
  transactionalAnalytics: `${prefix}transactional-analytics`,
  whatsapp: `${prefix}whatsapp`,
  whatsappTemplate: `${prefix}whatsapp-template`,
  whatsappConfig: `${prefix}whatsapp-config`,
  whatsappSend: `${prefix}send`,
  config: `${prefix}config`,
  contactsRenewed: `${prefix}contacts-renewed`,
  charts: `${prefix}charts`,
  configLogs: `${prefix}config-logs/:type`,
  sendedMails: `${prefix}mail/sended-mails/:count/:date`,
  notifications: `${prefix}notifications`,
  whatsappLogs: `${prefix}whatsapp-logs`,
  whatsappAnalytics: `${prefix}whatsapp-analytics`,
  emailConfig: `${prefix}email-config`,
};

export default routesCodes;
