import DomainApi from 'libs/api/domains';
import { useDomains } from '../../../redux/domain/use-domains';
import { useEffect, useState } from 'react';
import moment from 'moment';

const api = new DomainApi();
const useCharts = () => {
  const { currentDomain } = useDomains();
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [newData, setNewData] = useState([]);
  const [availableKeys, setAvailableKeys] = useState([]);

  useEffect(() => {
    if (currentDomain.id) {
      (async () => {
        setLoading(true);
        try {
          const dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
          const dateTo = moment().format('YYYY-MM-DD');
          const {
            data: { data },
          } = await api.getAnalytics(currentDomain.id, {
            params: {
              date_from: dateFrom,
              date_to: dateTo,
              type: 'day',
            },
          });

          console.log('data=', data);

          const days = Object.keys(data);
          let newChartData = days.map((day) => ({
            name: day,
            SMS: data[day].sms || null,
            'NL Whatsapp': data[day].nl_whatsapp || null,
            'NL email': data[day].nl_email || null,
            'NL SMS': data[day].nl_sms || null,
            Transazionali: data[day].transactional || null,
          }));

          newChartData = newChartData.map((item) => {
            const newItem = { ...item };
            newItem.name = moment(item.name, 'YYYY-MM-DD').format('DD MMMM YY');
            if (!item.SMS) {
              delete newItem.SMS;
            }
            if (!item['NL Whatsapp']) {
              delete newItem['NL Whatsapp'];
            }
            if (!item['NL email']) {
              delete newItem['NL email'];
            }
            if (!item['NL SMS']) {
              delete newItem['NL SMS'];
            }
            if (!item.Transazionali) {
              delete newItem.transactional;
            }

            return newItem;
          });

          setNewData(newChartData);

          let keys = ['SMS', 'NL email', 'Transazionali'];
          if (!currentDomain?.sms?.length) {
            keys = keys.filter((item) => item !== 'SMS' && item !== 'NL SMS');
          }
          if (!currentDomain?.notifications?.length) {
            keys = keys.filter((item) => item !== 'Transazionali');
          }
          if (!currentDomain?.newsletters?.length) {
            keys = keys.filter((item) => item !== 'NL email');
          }
          if (!currentDomain?.whatsapp?.length) {
            keys = keys.filter((item) => item !== 'NL Whatsapp');
          }
          setAvailableKeys(keys);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentDomain?.id]);

  return {
    dataChart,
    loading,
    newData,
    availableKeys,
  };
};

export default useCharts;
