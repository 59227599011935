import NewsletterCompaniesApi from 'libs/api/newsletter_companies';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDomains } from '../../../redux/domain/use-domains';
import DomainApi from '../../../libs/api/domains';

const apiNewsletter = new NewsletterCompaniesApi('newsletter_companies');
const apiWhatsapp = new NewsletterCompaniesApi('whatsapp_newsletters');
const companiesApi = new DomainApi('');

const useCalendar = () => {
  const { currentDomain } = useDomains();
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [startDateValue, setStartDateValue] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [data, setData] = useState({
    newsletterCount: 0,
    whatsappCount: 0,
  });

  const fetchPlaned = async () => {
    setLoading(true);
    try {
      const { data: dataNewsletter } = await apiNewsletter.getPlaned({
        by_day: moment(value).format('YYYY-MM-DD'),
      });
      let whatsappCount = 0;
      if (currentDomain.whatsapp?.length > 0) {
        const { data: dataWhatsapp } = await apiWhatsapp.getPlaned({
          by_day: moment(value).format('YYYY-MM-DD'),
        });
        whatsappCount = dataWhatsapp.total_count;
      }

      setData({
        newsletterCount: dataNewsletter.total_count,
        whatsappCount,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchPlaned();
    })();
  }, [value]);

  useEffect(() => {
    (async () => {
      if (currentDomain?.id) {
        setLoading(true);
        try {
          const { data } = await companiesApi.getNewsletterDays(
            currentDomain.id,
            startDateValue,
          );
          setAvailableDates(data.data || []);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [startDateValue, currentDomain]);

  return {
    value,
    onChange,
    loading,
    data,
    currentDomain,
    setStartDateValue,
    availableDates,
  };
};

export default useCalendar;
