import React from 'react';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import { useTranslation } from 'react-i18next';
import './index.scss';
import TransactionalChartPage from './transactionalChartsCharts';
import NewsletterChartsPage from './newsletterCharts';
import { useDomains } from '../../redux/domain/use-domains';
import SmsChartPage from './smsChartsCharts';

const ChartPageByType = () => {
  const { t } = useTranslation();
  const { currentSelectedDataType } = useDomains();
  const breadcrumbs = [
    { path: '/', name: `${t('home.home')}` },
    { path: '/', name: `${t('Grafici')}` },
  ];

  return (
    <div className='home-page'>
      <div className='headerMain'>
        <div className='title'>
          <div className='text'>{t('Grafici')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb classic breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      {currentSelectedDataType === 'newsletter' && <NewsletterChartsPage />}
      {currentSelectedDataType === 'notification' && <TransactionalChartPage />}
      {currentSelectedDataType === 'sms' && <SmsChartPage />}
    </div>
  );
};

export default ChartPageByType;
