import { toast } from 'react-toastify';
import { notification } from 'antd';
class UIHelper {

    static closeNotification = (key) => {
        notification.close(key);
    }

    static showRequestErrorToast = () => {
        notification.config({
            duration: 10,
        });
        toast.error('Error');
    };

    static showNotFoundErrorToast = () => {
        notification.config({
            duration: 10,
        });
        toast.error('Not found');
    };

    static showCreateOrUpdateSuccess = () => {
        notification.config({
            duration: 10,
        });
        toast.success('Successfully');
    };

    static showServerConfigError = () => {
        notification.config({
            duration: 10,
        });
        toast.error('Oops, server error');
    };

    static openNotificationWithIcon = (type, message, description) => {
        notification.config({
            duration: 10,
        });
        notification[type]({
            message,
            description,
        });
    };
    static serverError = () => {
        notification.config({
            duration: 4,
        });
        notification['error']({
            message: 'Sorry something went wrong. Try later.',
        });
    };

    static serverAxiosError = (errors) => {
        notification.config({
            duration: 4,
        });
        errors?.forEach(error => {
            notification['error']({
                message: error.messages?.join(' '),
            });
        });

    };

    static parseUploadParsError(meesages, parsMessages, key) {
        notification.config({
            duration: 10,
        });
        notification.open({
            message: 'File data error',
            key,
            description: (
                <div style={{
                    maxHeight: 400,
                    overflowY: 'scroll'
                }}>
                    <div>
                        {meesages.map((item, index) => (
                            <p style={{ marginLeft: 4 }} key={index}>
                                {item}
                            </p>
                        ))}
                    </div>
                    <div>
                        {parsMessages.map((item, index) => (
                            <p style={{ marginLeft: 4 }} key={index}>
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            ),
            className: 'custom-class',
            style: {
                width: 600,
            },
            duration: 0,
        });
    }
}

export default UIHelper;
