import { Select } from 'antd';
import styles from './style.module.scss';

const MySelect = (props) => {
  const { className, children, ...rest } = props;
  return (
    <Select className={styles.myTable + ` ${className}`} {...rest}>
      {children}
    </Select>
  );
};

export default MySelect;
