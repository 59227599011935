import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import React from 'react';
import styles from './style.module.scss';
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  Spin,
  Switch,
  Typography,
} from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import useNotification from './useNotification';
import MySwitch from '../../components/atoms/mySwitch';

const NotificationPage = () => {
  const { t } = useTranslation();
  const {
    loading,
    handleSave,
    form,
    isTransactional,
    scheduledReports,
    handleOk,
    editEmailId,
    setEditEmailId,
    setLocalEditData,
    updateEmail,
    handleDeleteScheduledReports,
    deleteLoading,
    updateLoading,
    localEditData,
    handleChangeEditEmailValues,
    handleChangeSwitch,
    isContacts,
  } = useNotification();
  const breadcrumbs = [
    { path: '/', name: t('compose.home') },
    { path: '/notification', name: t('Notifiche') },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.text}>{t('Notifiche')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      <div>
        <Spin spinning={loading}>
          <Form form={form} name='dynamic_rule'>
            <p>{t('Email')}</p>

            <Form.Item
              className={styles.notificationFormItem}
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input placeholder='Email' />
            </Form.Item>

            {isContacts && (
              <Form.Item
                className={styles.notificationFormItem}
                name='total_report'
                label={t('Report giornaliero operazioni sui contatti')}
              >
                <MySwitch />
              </Form.Item>
            )}

            {!isTransactional && !isContacts && (
              <>
                <Form.Item
                  className={styles.notificationFormItem}
                  name='detailed_report'
                  label={t('Report invio newslette')}
                >
                  <MySwitch />
                </Form.Item>
              </>
            )}
            {isTransactional && (
              <Form.Item
                className={styles.notificationFormItem}
                name='transactional_fault'
                label={t('Report giornaliero messaggi ko')}
              >
                <MySwitch />
              </Form.Item>
            )}
          </Form>
          <Row justify={'end'}>
            <Col>
              <Button
                loading={scheduledReports.loading}
                type='primary'
                onClick={handleOk}
                className={styles.saveBtn}
              >
                {t('Add')}
              </Button>
            </Col>
          </Row>
          <List
            size='small'
            header={<div>Emails</div>}
            dataSource={scheduledReports.data}
            renderItem={(item) => {
              const isEditEmail = editEmailId === item.id;
              const editIcon = isEditEmail ? (
                <CloseCircleOutlined />
              ) : (
                <EditOutlined />
              );

              const closeIcon = isEditEmail ? (
                <SaveOutlined />
              ) : (
                <DeleteOutlined />
              );
              return (
                <List.Item
                  actions={[
                    <Button
                      className={styles.notificationEditIcon}
                      icon={editIcon}
                      onClick={() => {
                        if (isEditEmail) {
                          setEditEmailId('');
                        } else {
                          setEditEmailId(item.id);
                          setLocalEditData({ ...item });
                        }
                      }}
                    />,
                    <Button
                      className={styles.notificationDeleteIcon}
                      icon={closeIcon}
                      onClick={() => {
                        if (isEditEmail) {
                          updateEmail();
                        } else {
                          handleDeleteScheduledReports(item.id);
                        }
                      }}
                      loading={deleteLoading === item.id || updateLoading}
                      disabled={deleteLoading && deleteLoading !== item.id}
                    />,
                  ]}
                >
                  {editEmailId !== item.id && (
                    <Row gutter={[16, 4]}>
                      <Col span={24}>{item.email}</Col>
                      <Col span={isContacts ? 24 : 0}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report giornaliero operazioni sui contatti')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch checked={item.total_report} />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={isTransactional || isContacts ? 0 : 24}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report invio newslette')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch checked={item.detailed_report} />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={isTransactional ? 24 : 0}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report giornaliero messaggi ko')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch checked={item.transactional_fault} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {editEmailId === item.id && (
                    <Row gutter={[16, 4]}>
                      <Col span={24}>
                        <Input
                          onChange={handleChangeEditEmailValues}
                          placeholder='Email'
                          value={localEditData.email}
                          label={'Email'}
                        />
                        {!localEditData.email && (
                          <p>
                            <Text type='danger'>Email is Required</Text>
                          </p>
                        )}
                      </Col>
                      <Col span={isContacts ? 24 : 0}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report giornaliero operazioni sui contatti')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch
                              checked={localEditData.total_report}
                              onChange={handleChangeSwitch('total_report')}
                              name={'total_report'}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={isTransactional || isContacts ? 0 : 24}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report invio newslette')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch
                              checked={localEditData.detailed_report}
                              onChange={handleChangeSwitch('detailed_report')}
                              name={'detailed_report'}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={isTransactional ? 24 : 0}>
                        <Row gutter={16} align={'middle'}>
                          <Col>
                            <Typography.Text strong>
                              {t('Report giornaliero messaggi ko')}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <MySwitch
                              checked={localEditData.transactional_fault}
                              onChange={handleChangeSwitch(
                                'transactional_fault',
                              )}
                              name={'transactional_fault'}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </List.Item>
              );
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default NotificationPage;
