import React from 'react';
import './index.scss';

class DemoLoadingPage extends React.Component {
    render() {
        return (
            <div className='loading-demo-container'>
                <div className='lds-ring'><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
}


export default DemoLoadingPage;
