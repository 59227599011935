import Api from 'libs/api';

class SMSApi extends Api {
  constructor() {
    super('');
  }

  getNewsletterChartAnalytics(params) {
    return this.buildQuery('get', { ...params }, 'sms/fetch_chart_analytics');
  }

  getUserServicesAnalytics(id, params) {
    return this.buildQuery('get', { ...params }, `user_services/${id}`);
  }

  getLogs(params) {
    return this.buildQuery('get', params, 'sms');
  }

  getMessageChartAnalytics(id, params) {
    return this.buildQuery('get', params, 'sms/fetch_message_analytics');
  }

  getBackupServiceAnalytics(params) {
    return this.buildQuery('get', params, 'sms/analytics_backup_service');
  }

  getPlaceHolder(id) {
    return this.buildQuery('get', {}, `companies/${id}/fetch_contact_fields`);
  }

  saveTemplate(data) {
    return this.buildQuery(
      'post',
      {
        data: {
          ...data,
        },
      },
      'sms_templates',
    );
  }

  updateTemplate(data) {
    return this.buildQuery(
      'patch',
      {
        data,
      },
      `sms_templates/${data.id}`,
    );
  }

  getTemplates(params) {
    return this.buildQuery('get', params, 'sms_templates');
  }
  deleteTemplate(id, params) {
    return this.buildQuery('post', params, `sms_templates/${id}/soft_delete`);
  }

  copyTemplate(params) {
    return this.buildQuery('post', params, 'mail_templates/copy_template');
  }
}

export default SMSApi;
