class Helpers {
  static downloadFile(response, name) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Download complete remove element
    window.URL.revokeObjectURL(url); // Release blob object
  }
  static numberWithCommas(num, prefix = '') {
    if (0 == num) {
      return '0' + prefix;
    }
    if (!num) return '';

    const newNum = num.toString().replace('.', ',');

    return newNum?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + prefix;
  }
}

export default Helpers;
