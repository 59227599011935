import styles from './style.module.scss';
import logo from 'assets/images/page-logo.png';
import { useTranslation } from 'react-i18next';

const MainPageLogo = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainLogoContainer}>
      <div className={styles.textContainer}>
        <p className={styles.title}>{t('Bentornato Rimailer')}</p>
        <p className={styles.description}>
          {t('Ecco un riepilogo delle tue attività')}
        </p>
      </div>
      <img className={styles.logo} src={logo} />
    </div>
  );
};

export default MainPageLogo;
