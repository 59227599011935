import React, { useMemo } from 'react';
import { Typography, Tooltip } from 'antd';
import moment from 'moment';

import './style.scss';
import MyTable from 'components/atoms/myTable';

const getColorByStatus = (status) => {
  switch (status) {
    case 'delivered':
      return '#0e9800';
    case 'failed':
      return '#ea4e29';
    case 'pending':
      return '#1aa4cc';
    default:
      return '#000000';
  }
};

const ContactsTable = ({
  t,
  analyticsData,
  pagination,
  setPagination,
  tableLoading,
  total,
  i18n,
}) => {
  const cols = useMemo(() => {
    return [
      {
        title: t('analytics.table.email'),
        dataIndex: 'email',
      },
      {
        title: t('analytics.table.subject'),
        dataIndex: 'subject',
        render: (text) => {
          return (
            <Tooltip placement='top' title={text}>
              <Typography.Text className='subject' ellipsis>
                {text}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => {
          return (
            <span className='status' style={{ color: getColorByStatus(text) }}>
              {text}
            </span>
          );
        },
      },
      {
        title: t('analytics.table.error_message'),
        dataIndex: 'error_message',

        render: (text) => {
          return (
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: 'more',
              }}
              style={{ width: 320, margin: 0 }}
            >
              {text}
            </Typography.Paragraph>
          );
        },
      },
      {
        title: t('analytics.table.title1'),
        dataIndex: 'date',
        render: (text) => {
          return <span>{moment(text).format('DD/MM/YYYY HH:mm')}</span>;
        },
      },
    ];
  }, [i18n.language]);

  return (
    <div className='analytics-table'>
      <MyTable
        loading={tableLoading}
        bordered
        pagination={{
          position: ['topRight', 'none'],
          pageSize: pagination?.per_page || 10,
          onChange: setPagination,
          total,
        }}
        columns={cols}
        rowKey='id'
        dataSource={analyticsData}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

ContactsTable.propTypes = {};

export default ContactsTable;
