import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import TransactionalsApi from 'libs/api/transactionals';
import { useDomains } from 'redux/domain/use-domains';
const api = new TransactionalsApi();

const useDomainCharts = (id, date) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  const { currentNotification } = useDomains();
  let secondDate = moment(date).add(1, 'week').isAfter(new Date())
    ? moment()
    : moment(date).add(1, 'week');
  const range = [
    moment(new Date(date), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    secondDate.format('YYYY-MM-DD'),
  ];
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(range);

  const [chartsData, setChartsData] = useState({
    todayData: [],
    yesterdayData: [],
    monthData: [],
  });

  const getDomainsAnalyticsData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          email_id: currentNotification.id,
        },
      };
      const { data } = await api.getNewsletterChartAnalytics(params);
      let todayData = [];
      let yesterdayData = [];
      let monthData = [];
      const colors = {
        delivered: '#00C49F',
        pending: '#FFBB28',
        failed: '#FF8042',
      };
      let isEmpty = Object.entries(data?.data?.today || {}).every(
        (i) => i[1] === 0,
      );
      Object.entries(data?.data?.today || {}).forEach((i) => {
        if (i[1] === 0) return;
        todayData.push({
          name: t(i[0].toUpperCase()),
          value: i[1],
          color: colors[i[0]],
        });
      });

      if (todayData.length === 0) {
        todayData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }

      Object.entries(data?.data?.yesterday || {}).forEach((i) => {
        if (i[1] === 0) return;
        yesterdayData.push({
          name: t(i[0].toUpperCase()),
          value: i[1],
          color: colors[i[0]],
        });
      });
      if (yesterdayData.length === 0) {
        yesterdayData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }
      Object.entries(data?.data?.month || {}).forEach((i) => {
        if (i[1] === 0) return;
        monthData.push({
          name: t(i[0].toUpperCase()),
          value: i[1],
          color: colors[i[0]],
        });
      });
      if (monthData.length === 0) {
        monthData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }
      setChartsData({
        todayData,
        yesterdayData,
        monthData,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, currentNotification, language]);

  return {
    chartsData,
    loading,
    dateRange,
    setDateRange,
  };
};

export default useDomainCharts;
