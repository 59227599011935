import DomainApi from 'libs/api/domains';
import { useDomains } from 'redux/domain/use-domains';
import { useEffect, useState } from 'react';

const api = new DomainApi();

const useLastNewsletter = () => {
  const { currentDomain } = useDomains();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    created: 0,
    delivered: 0,
    failed: 0,
    opened: 0,
    type: '',
  });

  useEffect(() => {
    setLoading(true);
    if (currentDomain?.id) {
      (async () => {
        try {
          const { data } = await api.getLastNewsletter(currentDomain.id);
          setData(data.data);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentDomain?.id]);

  return {
    data,
    loading,
  };
};

export default useLastNewsletter;
