import React from 'react';
import useDomainCharts from './useDomainCharts';
import { Col, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
  Brush,
} from 'recharts';
import './style.scss';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReportPdfPage = (props) => {
  const { id, date, title } = props.match.params;
  let query = useQuery();
  localStorage.setItem('auth_token', query.get('token'));
  const { chartsData, loading } = useDomainCharts(
    id,
    date,
    query.get('domain_id'),
  );
  const { t } = useTranslation();

  const breadcrumbs = [
    { path: '/', name: 'Home' },
    { path: '/analytics', name: 'Analytics' },
    { path: '/analytics/domain-charts', name: t('Newsletter Charts') },
  ];

  const calculateChartWidth = (dataLength) => {
    let width = dataLength * 135;
    const defaultWidth = window.innerWidth - 266 - 24 - 60;
    if (width < defaultWidth) {
      width = defaultWidth;
    }

    return width;
  };

  return (
    <div className={'domainChartReport'}>
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Typography.Title color={'red'} level={1}>
              {title}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Sent mail over time')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row>
              <Col
                span={16}
                style={{
                  height: 400,
                  width: '100%',
                }}
              >
                {chartsData.sendData.length > 0 && (
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      width={500}
                      height={400}
                      data={chartsData.sendData}
                      margin={{
                        top: 5,
                        right: 100,
                        left: 20,
                        bottom: 120,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='name'
                        tick={{
                          angle: -35,
                          textAnchor: 'end',
                          dominantBaseline: 'ideographic',
                        }}
                      />
                      <YAxis allowDecimals={false} />
                      <Tooltip />
                      <Legend
                        verticalAlign='top'
                        wrapperStyle={{ lineHeight: '40px' }}
                      />
                      <ReferenceLine y={0} stroke='#000' />
                      <Bar dataKey={t('Count')} fill='#8884d8' />
                      <Brush
                        y={320}
                        dataKey='name'
                        height={30}
                        stroke='#8884d8'
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Open mail over time')}
            </Typography.Title>
          </Col>
          <Col
            span={16}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.openedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={t('Count')} fill='#8884d8' />
                <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Failed mail over time')}
            </Typography.Title>
          </Col>
          <Col
            span={16}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.failedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={t('Count')} fill='#8884d8' />
                <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row className={'chartColl'}>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Pending mail over time')}
            </Typography.Title>
          </Col>
          <Col
            span={16}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.pendingData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={t('Count')} fill='#8884d8' />
                <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Clicked links over time')}
            </Typography.Title>
          </Col>
          <Col
            span={16}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={400}
                data={chartsData.clickedData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={t('Count')} fill='#8884d8' />
                <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Unsubscription over time')}
            </Typography.Title>
          </Col>
          <Col
            span={16}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            <ResponsiveContainer width='100%' height='100%' baseValue={'N/A'}>
              <BarChart
                width={500}
                height={400}
                data={chartsData.unsubscriptionData}
                margin={{
                  top: 5,
                  right: 100,
                  left: 20,
                  bottom: 120,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='name'
                  tick={{
                    angle: -35,
                    textAnchor: 'end',
                    dominantBaseline: 'ideographic',
                  }}
                />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend
                  verticalAlign='top'
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <ReferenceLine y={0} stroke='#000' />
                <Bar dataKey={t('Count')} fill='#8884d8' />
                <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ReportPdfPage;
