import styles from './style.module.scss';
import MainPageLogo from './mainPageLogo';
import React from 'react';
import { Col, Row } from 'antd';
import SmsSection from './smsSection';
import ProgressSection from './progressSection';
import CalendarSection from './calendarSection';
import ChartSection from './chartSection';
import QueueSizesSection from './queueSizes';

const NewHome = () => {
  return (
    <div className={styles.container}>
      <MainPageLogo />
      <Row gutter={[16, 16]} className={styles.sections}>
        <Col span={8}>
          <SmsSection />
        </Col>
        <Col span={16}>
          <ChartSection />
        </Col>
        <Col span={8}>
          <ProgressSection />
        </Col>
        <Col span={16}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CalendarSection />
            </Col>
            <Col span={24}>
              <QueueSizesSection />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewHome;
