import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import './style.scss';

const CustomBreadcrumb = ({ breadcrumbs, classic }) => {
    return (
        <div className='breadcrumbs'>
            <Breadcrumb style={{ margin: '0 0' }}>
                {breadcrumbs.map((item, index) => {
                    return (
                        <Breadcrumb.Item
                            className={cx(
                                'breadcrumbs-items',
                                !classic &&
                                    index === breadcrumbs.length - 1 &&
                                    'breadcrumbs-items-active',
                            )}
                            key={index}
                        >
                            {index === breadcrumbs.length - 1 ? (
                                <a>{item.name}</a>
                            ) : (
                                <Link to={item.path}>{item.name}</Link>
                            )}
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};

export default CustomBreadcrumb;
