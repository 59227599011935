import Api from 'libs/api';

class DomainApi extends Api {
  constructor(resource = '') {
    super(resource);
  }

  getUser() {
    return this.buildQuery('get', {}, 'users/me');
  }

  getDomains() {
    return this.buildQuery('get', {}, 'domains');
  }

  getQueueSizes(id) {
    return this.buildQuery('get', {}, 'domains/queue_sizes');
  }
  getDomainEmails(id) {
    return this.buildQuery(
      'get',
      {
        params: {
          domain_id: id,
        },
      },
      'domain_emails',
    );
  }

  getDomainEmailsAnalytics(id, params) {
    return this.buildQuery(
      'get',
      params,
      `domain_emails/${id}/fetch_contact_analytics`,
    );
  }

  getCompanyEmailsAnalytics(id, params) {
    return this.buildQuery(
      'get',
      params,
      `companies/${id}/fetch_contact_analytics`,
    );
  }

  getNewsletterDays(id, day) {
    return this.buildQuery(
      'get',
      {
        params: {
          day,
        },
      },
      `companies/${id}/newsletter_days`,
    );
  }

  getCompanyEmailsAnalyticsPDF(id, params) {
    return this.buildQuery(
      'get',
      {
        ...params,
        headers: {
          Accept: 'application/pdf',
        },
        props: { responseType: 'blob' },
      },
      `companies/${id}/fetch_contact_analytics.pdf`,
    );
  }

  getNewsletterChartAnalytics(id, params) {
    return this.buildQuery(
      'get',
      params,
      `newsletter_companies/${id}/fetch_chart_analytics`,
    );
  }

  getNewsletterChartAnalyticsPDF(id, params) {
    return this.buildQuery(
      'get',
      {
        ...params,
        headers: {
          Accept: 'application/pdf',
        },
        props: { responseType: 'blob' },
      },
      `newsletter_companies/${id}/fetch_chart_analytics.pdf`,
    );
  }

  getMessageChartAnalytics(id, params) {
    return this.buildQuery(
      'get',
      params,
      `domain_emails/${id}/fetch_message_analytics`,
    );
  }

  getDomainAnalytics(id) {
    return this.buildQuery('get', {}, `companies/${id}/email_analytics`);
  }

  getProgressAnalyticsForDomainEmails(id, params) {
    return this.buildQuery(
      'get',
      {
        params,
      },
      `domain_emails/${id}/analytics`,
    );
  }

  getProgressAnalyticsForSms(id, params = {}) {
    return this.buildQuery(
      'get',
      {
        params,
      },
      `user_services/${id}`,
    );
  }

  getMessageChartAnalyticsPDF(id, params) {
    return this.buildQuery(
      'get',
      {
        ...params,
        headers: {
          Accept: 'application/pdf',
        },
        props: { responseType: 'blob' },
      },
      `domain_emails/${id}/fetch_message_analytics.pdf`,
    );
  }

  updateEmail(id, value, domainId) {
    return this.buildQuery(
      'put',
      {
        data: {
          auto_unreachable: value,
        },
        params: {
          domain_id: domainId,
        },
      },
      `domain_emails/${id}/update_email`,
    );
  }

  getScheduledReports(params) {
    return this.buildQuery(
      'get',
      {
        params,
      },
      'scheduled_reports',
    );
  }

  deleteScheduledReports(id, params) {
    return this.buildQuery(
      'delete',
      {
        params,
      },
      `scheduled_reports/${id}`,
    );
  }

  createScheduledReports(params, data) {
    return this.buildQuery(
      'post',
      {
        data,
        params,
      },
      'scheduled_reports',
    );
  }

  updateScheduledReports(params, id, data) {
    return this.buildQuery(
      'put',
      {
        data,
        params,
      },
      `scheduled_reports/${id}`,
    );
  }

  getCompany(id) {
    return this.buildQuery('get', {}, `companies/${id}`);
  }

  getLastNewsletter(id) {
    return this.buildQuery('get', {}, `companies/${id}/last_newsletter`);
  }

  getAnalytics(id, params) {
    return this.buildQuery('get', params, `companies/${id}/analytics`);
  }
}

export default DomainApi;
