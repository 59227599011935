import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import NewsletterChartsPage from './newsletterCharts';

const WhatsappAnalyticsPage = () => {
  const { t } = useTranslation();
  return (
    <div className='SmsAnalyticsPage'>
      <div className='headerMain'>
        <div className='title'>
          <div className='text'>{t('Analytics')}</div>
        </div>
      </div>
      <NewsletterChartsPage />
    </div>
  );
};

export default WhatsappAnalyticsPage;
