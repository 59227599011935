import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import useCharts from './useCharts';
import { Spin } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { BarChart, Bar, Cell } from 'recharts';

const MyResponsiveLineDemo = ({ data, availableKeys }) => (
  <ResponsiveContainer width='100%' height='100%'>
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='name' />
      <YAxis scale='log' domain={['auto', 'auto']} />
      <Tooltip />
      <Legend iconSize={24} />
      {availableKeys.includes('SMS') && (
        <Bar dataKey='SMS' fill='rgb(232, 193, 160)' />
      )}
      {availableKeys.includes('NL Whatsapp') && (
        <Bar dataKey='NL Whatsapp' fill='rgb(244, 117, 96)' />
      )}
      {availableKeys.includes('NL email') && (
        <Bar dataKey='NL email' fill='rgb(190, 186, 218)' />
      )}
      {availableKeys.includes('NL SMS') && (
        <Bar dataKey='NL SMS' fill='rgb(232, 168, 56)' />
      )}
      {availableKeys.includes('Transazionali') && (
        <Bar dataKey='Transazionali' fill='rgb(97, 205, 187)' />
      )}
    </BarChart>
  </ResponsiveContainer>
);

const ChartSection = () => {
  const { t } = useTranslation();
  const { availableKeys, loading, newData } = useCharts();

  return (
    <Spin spinning={loading}>
      <div className={styles.card}>
        <p className={styles.title}>{t('Volumi di invio')}</p>
        <br />
        <MyResponsiveLineDemo availableKeys={availableKeys} data={newData} />
      </div>
    </Spin>
  );
};

export default ChartSection;
