import WhatsappApi from 'libs/api/whatsapp';
import { useEffect, useState } from 'react';
import { useDomains } from 'redux/domain/use-domains';
import UIHelper from 'services/UIHelper';
import DomainApi from '../../libs/api/domains';
import { primaryVariables } from '../../common/constants';

const api = new DomainApi('domain_emails');

const useEmailConfig = () => {
  const { currentNewsletter, isTransactional, currentNotification } =
    useDomains();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email_alias: '',
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (currentNewsletter?.id || currentNotification?.id) {
          let id = '';
          if (isTransactional) {
            id = currentNotification?.id;
          } else {
            id = currentNewsletter?.id;
          }
          const {
            data: { domain_email },
          } = await api.get(`/${id}`);
          console.log('data=', data);
          console.log('currentNewsletter=', currentNewsletter);
          setData({
            email_alias: domain_email.email_alias,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentNewsletter, isTransactional, currentNotification]);

  const handleSave = async () => {
    try {
      setLoading(true);
      let id = '';
      let key = '';
      if (isTransactional) {
        id = currentNotification?.id;
        key = primaryVariables.currentNotification;
      } else {
        id = currentNewsletter?.id;
        key = primaryVariables.currentNewsletter;
      }
      const {
        data: { domain_email },
      } = await api.put(`/${id}`, {
        data: {
          email_alias: data.email_alias,
        },
      });

      localStorage.setItem(
        primaryVariables.currentNotification,
        JSON.stringify({
          ...currentNewsletter,
          ...domain_email,
        }),
      );
      UIHelper.openNotificationWithIcon(
        'success',
        'Sucesso',
        'L’operazione è stata completata con successo',
      );
    } catch (e) {
      console.log(e);
      UIHelper.serverError();
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData,
    loading,
    handleSave,
  };
};

export default useEmailConfig;
