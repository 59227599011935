import Api from 'libs/api';

class ContactsApi extends Api {
  constructor() {
    super('');
  }

  // getDomainEmails(params) {
  //     return this.buildQuery('get', params, 'domain_emails');
  // }

  startReload(id) {
    return this.buildQuery('put', {}, `analytics/${id}`);
  }

  getAnalytics(data) {
    return this.buildQuery('get', { params: data }, 'analytics');
  }

  getAnalyticsTransactionals(data) {
    return this.buildQuery('get', { params: data }, 'transactionals');
  }

  getAnalyticsToDownload(data) {
    return this.buildQuery(
      'get',
      {
        params: data,
        props: {
          responseType: 'blob',
        },
      },
      'analytics.xlsx',
    );
  }

  getAnalyticsToDownloadForTransactional(data) {
    return this.buildQuery(
      'get',
      {
        params: data,
      },
      'transactionals.xlsx',
    );
  }

  downloadReport(params) {
    return this.buildQuery('get', { ...params }, 'analytics/fetch_analytics');
  }
}

export default ContactsApi;
