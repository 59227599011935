import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useLastNewsletter from './useLastNewsletter';
import { Spin } from 'antd';

const SmsSection = () => {
  const { t } = useTranslation();
  const { data, loading } = useLastNewsletter();

  const deliveredPercentage =
    data?.created > 0
      ? ((data?.delivered / data?.created) * 100).toFixed(0)
      : 0;

  const failedPercentage =
    data?.created > 0 ? ((data?.failed / data?.created) * 100).toFixed(0) : 0;

  const openedPercentage =
    data?.created > 0 ? ((data?.opened / data?.created) * 100).toFixed(0) : 0;

  return (
    <div className={styles.card}>
      <Spin spinning={loading}>
        <p className={styles.title}>
          {t('Report ultimo invio')} ({data.type}) {data.title || '-'}
        </p>
        <br />
        <div className={styles.dataSection}>
          <div className={styles.dataSection_dataContainer1}>
            <span className={styles.dataSection_title}>
              {/*<UserOutlined />*/}
              {t('Destinatari')}
            </span>
            <span>
              <b>{data.created}</b>
            </span>
          </div>
          <div className={styles.dataSection_dataContainer2}>
            <span className={styles.dataSection_title}>
              {/*<GlassesIcon />*/}
              {t('Ricevuti')}
            </span>
            <span>
              <b>
                {data.delivered} ({deliveredPercentage}%)
              </b>
            </span>
          </div>
          <div className={styles.dataSection_dataContainer3}>
            <span className={styles.dataSection_title}>
              {/*<CursorIcon />*/}
              {t('Falliti')}
            </span>
            <span>
              <b>
                {data.failed} ({failedPercentage}%)
              </b>
            </span>
          </div>
          <div className={styles.dataSection_dataContainer4}>
            <span className={styles.dataSection_title}>
              {/*<CursorIcon />*/}
              {t('Opened')}
            </span>
            <span>
              <b>
                {data.opened}({openedPercentage}%)
              </b>
            </span>
          </div>
        </div>
        <br />
      </Spin>
    </div>
  );
};

export default SmsSection;
