import DomainApi from 'libs/api/domains';
import { useDomains } from 'redux/domain/use-domains';
import { useEffect, useState } from 'react';

const api = new DomainApi();

const useQueueSizes = () => {
  const { currentDomain } = useDomains();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (currentDomain?.id) {
      (async () => {
        try {
          const { data } = await api.getQueueSizes();
          console.log('data=', data);
          setData(data.queue_sizes || []);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentDomain?.id]);

  return {
    data,
    loading,
  };
};

export default useQueueSizes;
