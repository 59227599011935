import React from 'react';
import { Modal, Input, Form } from 'antd';
import './style.scss';

const ChangePasswordModal = ({
    onClickChange,
    isModalVisible,
    setIsModalVisible,
    changeBtnLoading,
    backendErrors,
    setBackendErrors,
    t
}) => {
    const [form] = Form.useForm();

    const handleOk = async () => {
        setBackendErrors([]);
        try {
            const values = await form.validateFields();
            onClickChange(values, () => form.resetFields());
        } catch (errorInfo) {
            console.log(errorInfo);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    return (
        <>
            <Modal
                className='change-password-modal'
                title={t('layout.changePasswordModal.title')}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t('layout.changePasswordModal.okBtnText')}
                cancelText={t('layout.changePasswordModal.cancelBtnText')}
                okButtonProps={{
                    loading: changeBtnLoading,
                }}
            >
                <Form form={form} name='dynamic_rule'>
                    <p>{t('layout.changePasswordModal.oldPassword')}</p>
                    <Form.Item
                        name='current_password'
                        rules={[
                            {
                                required: true,
                                message: t('layout.changePasswordModal.inputPassword'),
                            },
                        ]}
                    >
                        <Input.Password placeholder='input password' />
                    </Form.Item>
                    <p>{t('layout.changePasswordModal.newPassword')}</p>
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: t('layout.changePasswordModal.newPasswordRequired'),
                            },
                            {
                                validator: (rule, value, cb) => {
                                    if (
                                        value &&
                                        form.getFieldValue('password').length <
                                            6
                                    ) {
                                        cb(
                                            t('layout.changePasswordModal.passwordIsTooShort'),
                                        );
                                    } else {
                                        cb();
                                    }
                                },
                            },
                        ]}
                    >
                        <Input.Password placeholder='input password' />
                    </Form.Item>
                    <p>{t('layout.changePasswordModal.confirmPassword')}</p>
                    <Form.Item
                        name='confirmNew'
                        rules={[
                            {
                                validator: (rule, value, cb) => {
                                    if (value === '') {
                                        cb(t('layout.changePasswordModal.confirmNewPasswordRequired'));
                                    } else if (
                                        form.getFieldValue('password') !== value
                                    ) {
                                        cb(t('layout.changePasswordModal.paswwordsDoNotMatch'));
                                    } else {
                                        cb();
                                    }
                                },
                            },
                        ]}
                    >
                        <Input.Password placeholder='input password' />
                    </Form.Item>
                </Form>
                {backendErrors.map(item => (
                    <div className='errors'>
                        {item.messages.map(errors => (
                            <p>{errors}</p>
                        ))}
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default ChangePasswordModal;
