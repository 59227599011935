import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import React from 'react';
import styles from './style.module.scss';
import MySwitch from 'components/atoms/mySwitch';
import { Button, Col, Row, Spin, Typography } from 'antd';
import MyInput, { MyTextArea } from 'components/atoms/myInput';
import useEmailConfig from './useEmailConfig';

const EmailConfigPage = () => {
  const { t } = useTranslation();
  const { data, setData, loading, handleSave } = useEmailConfig();
  const breadcrumbs = [
    { path: '/', name: t('compose.home') },
    { path: '/email-config', name: t('Email Config') },
  ];

  const onChangeInput = (e) => {
    setData({ ...data, email_alias: e.target.value });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.text}>{t('Email Config')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      <div>
        <Spin spinning={loading}>
          <Row gutter={[18, 18]}>
            <Col span={12}>
              <Typography.Text className={styles.label}>
                {t('Alias')}
              </Typography.Text>
              <MyInput
                placeholder={t('Alias')}
                rows={5}
                onChange={onChangeInput}
                value={data.email_alias}
              />
            </Col>
            <Col span={24}>
              <Button
                loading={loading}
                onClick={handleSave}
                type='primary'
                className={styles.saveBtn}
              >
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default EmailConfigPage;
