import * as types from './types';

export const loginUserRequest = (payload) => ({
  type: types.USER_LOGIN_REQUEST,
  payload,
});

export const resetSignInErrors = () => ({
  type: types.USER_LOGIN_FAILURE,
  payload: null,
});

export const setLocalization = payload => ({
  type: types.CHANGE_LOCALIZATION,
  payload,
});
