import { useEffect, useState } from 'react';
import { useDomains } from 'redux/domain/use-domains';
import { Form } from 'antd';
import DomainApi from '../../libs/api/domains';

const api = new DomainApi();

const useNotification = () => {
  const [form] = Form.useForm();
  const {
    currentDomain,
    isTransactional,
    currentNotification,
    currentNewsletter,
    currentSelectedDataType,
  } = useDomains();

  const isContacts = currentSelectedDataType === 'contacts';

  const domainId = isTransactional
    ? currentNotification.id
    : currentNewsletter.id;

  const [loading, setLoading] = useState(false);
  // const { currentDomain, currentNewsletter, currentNotification } =
  //   useDomains();
  const [editEmailId, setEditEmailId] = useState('');
  const [localEditData, setLocalEditData] = useState({});
  const [scheduledReports, setScheduledReports] = useState({
    data: [],
    loading: false,
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleGetScheduledReports = async () => {
    try {
      setScheduledReports((s) => ({
        ...s,
        loading: true,
      }));
      const params = isContacts
        ? {
            company_id: currentDomain.id,
          }
        : { domain_email_id: domainId };
      const {
        data: { scheduled_reports = [] },
      } = await api.getScheduledReports(params);
      setScheduledReports({
        data: scheduled_reports,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setScheduledReports((s) => ({
        ...s,
        loading: false,
      }));
    }
  };

  const handleAddScheduledReports = async (data) => {
    try {
      const params = isContacts
        ? {
            company_id: currentDomain.id,
          }
        : { domain_email_id: domainId };
      await api.createScheduledReports(params, data);
      await handleGetScheduledReports();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateScheduledReports = async (id, data) => {
    try {
      setUpdateLoading(true);
      const params = isContacts
        ? {
            company_id: currentDomain.id,
          }
        : { domain_email_id: domainId };
      await api.updateScheduledReports(params, id, data);
      await handleGetScheduledReports();
    } catch (e) {
      console.log(e);
    } finally {
      setUpdateLoading(false);
      setEditEmailId('');
      setLocalEditData({});
    }
  };

  const handleDeleteScheduledReports = async (id) => {
    try {
      setDeleteLoading(id);
      const params = isContacts
        ? {
            company_id: currentDomain.id,
          }
        : { domain_email_id: domainId };
      await api.deleteScheduledReports(id, params);
      await handleGetScheduledReports();
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      // handleAddScheduledReports(values);
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const handleChangeEditEmailValues = (e, ...rest) => {
    const { value } = e.target;
    setLocalEditData((s) => ({
      ...s,
      email: value,
    }));
  };

  const handleChangeSwitch = (name) => {
    return (value) => {
      setLocalEditData((s) => ({
        ...s,
        [name]: value,
      }));
    };
  };

  const updateEmail = () => {
    if (localEditData.email) {
      let data = {
        email: localEditData.email,
        total_report: localEditData.total_report,
        detailed_report: localEditData.detailed_report,
      };
      if (isTransactional) {
        data = {
          transactional_fault: localEditData.transactional_fault,
        };
      }
      handleUpdateScheduledReports(localEditData.id, data);
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleAddScheduledReports(values);
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetScheduledReports();
    })();
  }, [
    currentNotification,
    currentNewsletter,
    currentDomain,
    currentSelectedDataType,
  ]);

  return {
    loading,
    handleSave,
    form,
    isTransactional,
    scheduledReports,
    handleOk,
    editEmailId,
    setEditEmailId,
    setLocalEditData,
    updateEmail,
    handleDeleteScheduledReports,
    deleteLoading,
    updateLoading,
    localEditData,
    handleChangeEditEmailValues,
    handleChangeSwitch,
    isContacts,
  };
};

export default useNotification;
