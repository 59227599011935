import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { primaryVariables } from 'common/constants';
import logo from 'assets/images/main-pure-logo.png';
import AnimalLogo from 'assets/images/sign_in_logo.png';
import './style.scss';
const { Option } = Select;

const SetDomainModal = ({
  domains,
  setDomain,
  setIsModalVisible,
  isModalVisible,
  currentDomain,
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(currentDomain.id);
  const handleOk = () => {
    if (localValue) {
      setIsModalVisible(false);
      setDomain(localValue);
      localStorage.setItem(
        primaryVariables.emailKey,
        JSON.stringify(currentDomain),
      );
    }
  };

  const handleCancel = () => {
    if (currentDomain.id !== undefined) {
      setIsModalVisible(false);
    }
  };

  const onChangeDomain = (value) => {
    setLocalValue(value);
  };

  return (
    <Modal
      className='set-domain-email-modal'
      title={t('layout.changeDomain')}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      okText={t('layout.modalYesBtn')}
      cancelText={t('base.cancel')}
      okButtonProps={
        {
          // disabled: true,
          // loading: modalLoadingBtn
        }
      }
    >
      <div className='body'>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder={t('layout.changeDomain')}
          optionFilterProp='children'
          onChange={onChangeDomain}
          value={localValue}
          required
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {domains.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className='modalLogoBackground'>
        <img className='logo' src={logo} alt='logo' />
      </div>
      <div className='modalAnimalLogoBackground'>
        <img className='logo' src={AnimalLogo} alt='logo' />
      </div>
    </Modal>
  );
};

export default SetDomainModal;
