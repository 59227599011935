import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import Helpers from 'helpers';
import { useDomains } from 'redux/domain/use-domains';
import WhatsappApi from 'libs/api/whatsapp';

const api = new WhatsappApi();

const useDomainCharts = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  const { currentDomain, currentWhatsapp } = useDomains();

  const range = [
    moment(new Date()).subtract(1, 'week').format('DD/MM/YYYY'),
    moment(new Date()).format('DD/MM/YYYY'),
  ];
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(range);
  const [label, setLabel] = useState(t('Messaggi/ora'));
  const [chartsData, setChartsData] = useState({
    sendData: [],
    failData: [],
    readData: [],
    deliveredData: [],
  });

  const getDomainsAnalyticsData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          date_from: moment(dateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          date_to: moment(dateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          type: 'hour',
          whatsapp_service_id: currentWhatsapp.id,
        },
      };

      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      let formatType = 'DD/MM/YYYY HH:mm';
      let label = t('Messaggi/ora');
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
        formatType = 'DD/MM/YYYY';
        label = t('Messaggi/giorno');
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
        formatType = 'MMM/YYYY';
        label = t('Messaggi/mese');
      }
      setLabel(label);
      const { data } = await api.getMessageChartAnalytics(
        currentDomain.id,
        params,
      );
      const sendData = [];
      const failData = [];
      const readData = [];
      const deliveredData = [];

      const { send = {}, delivered = {}, fail = {}, read = {} } = data.data;
      const addFunction = (arr, i) => {
        arr.push({
          name: moment(i[0], 'YYYY-MM-DD HH:mm')
            .format(formatType)
            .toUpperCase(),
          [label]: i[1],
        });
      };

      Object.entries(send).forEach((i) => {
        addFunction(sendData, i);
      });

      Object.entries(fail).forEach((i) => {
        addFunction(failData, i);
      });

      Object.entries(read).forEach((i) => {
        addFunction(readData, i);
      });

      Object.entries(delivered).forEach((i) => {
        addFunction(deliveredData, i);
      });

      setChartsData({
        sendData,
        failData,
        readData,
        deliveredData,
      });
    } catch (e) {
      console.log(e);
      setChartsData({
        sendData: [],
        failData: [],
        readData: [],
        deliveredData: [],
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleDownloadPDF = async () => {
  //   setLoading(true);
  //   try {
  //     const params = {
  //       params: {
  //         date_from: moment(dateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
  //         date_to: moment(dateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
  //         type: 'hour',
  //         lang: language,
  //         whatsapp_service_id: currentWhatsapp.id,
  //       },
  //     };
  //
  //     const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
  //     const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
  //     if (secondDate.diff(firstDate, 'days') > 7) {
  //       params.params.type = 'day';
  //     }
  //
  //     if (secondDate.diff(firstDate, 'month', true) > 1) {
  //       params.params.type = 'month';
  //     }
  //
  //     const response = await api.getMessageChartAnalyticsPDF(
  //       currentDomain.id,
  //       params,
  //     );
  //
  //     Helpers.downloadFile(response, 'report.pdf');
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, currentWhatsapp, language]);

  return {
    chartsData,
    loading,
    dateRange,
    setDateRange,
    label,
    // handleDownloadPDF,
  };
};

export default useDomainCharts;
