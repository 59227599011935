import { all, takeLatest, fork, put } from 'redux-saga/effects';
import DomainApi from 'libs/api/domains';

import * as types from './types';

const api = new DomainApi();

function* getDomains() {
  try {
    const {
      data: { companies, user = {} },
    } = yield api.getUser();

    yield put({
      type: types.GET_DOMAINS,
      payload: companies,
      user,
    });
  } catch (e) {
    // yield put({
    //     type: types.USER_LOGIN_FAILURE,
    //     payload: e?.response?.data?.errors,
    // });
    console.log(e);
    console.log(e?.response?.status);
  }
}

function* watchGetDomainRequest() {
  yield takeLatest(types.GET_DOMAINS_REQUEST, getDomains);
}

export default function* dimainSagas() {
  yield all([fork(watchGetDomainRequest)]);
}
