import { useDispatch, useSelector } from 'react-redux';

import {
  getDomainsRequest,
  setCurrentDomainEmail,
  setDomainEmails,
} from './actions';
import {
  domainEmailsCurrentSelector,
  domainEmailsDataSelector,
} from './selectors';
import DomainApi from 'libs/api/domains';

const api = new DomainApi();

export const useDomainsEmails = () => {
  const dispatch = useDispatch();
  const emails = useSelector(domainEmailsDataSelector);
  const currentEmails = useSelector(domainEmailsCurrentSelector);

  function handleGetDomainEmails(id) {
    dispatch(getDomainsRequest(id));
  }

  function handleSetCurentEmail(currentEmail) {
    dispatch(setCurrentDomainEmail(currentEmail));
  }

  function resetMails() {
    dispatch(setDomainEmails([]));
  }

  function handleUpdateAutoUnreachableEmail(value) {
    dispatch(
      updateDomainsRequest(currentEmails.id, value, currentEmails.domain_id),
    );
  }

  return {
    emails,
    currentEmails,
    handleGetDomainEmails,
    handleSetCurentEmail,
    resetMails,
    handleUpdateAutoUnreachableEmail,
  };
};
