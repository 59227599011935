import * as types from './types';

const initialState = {
  signInInProcess: false,
  signInErrors: null,
  data: {},
  localization: 'en',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST:
      return {
        ...state,
        signInInProcess: true,
      };
    case types.USER_LOGIN_FAILURE:
      return {
        ...state,
        signInInProcess: false,
        signInErrors: action.payload,
      };
    case types.CHANGE_LOCALIZATION:
      return {
        ...state,
        localization: action.payload,
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        signInInProcess: false,
        data: {
          ...state.data,
          ...action.payload
        },
      };
    default:
      return state;
  }
};

export default userReducer;
