import React, { lazy, Suspense } from 'react';
import routesCodes from 'common/constants/route-codes';
import { ToastContainer } from 'react-toastify';
import { Route, Switch } from 'react-router-dom';
import Loading from 'pages/Loading';
import en_GB from 'antd/lib/locale/en_GB';
import it_IT from 'antd/lib/locale/it_IT';
import ProtectedRoute from './ProtectedRoute';

import ReportPdfPage from 'pages/ReportPdf';
import ReportPdfContactsPage from '../pages/reportPdfContacts';
import ReportPdfHomePage from '../pages/reportPdfHome';
import ReportPdfNewsletterPage from '../pages/reportPdfNewsletter';
import NewHome from '../pages/NewHome';
import ConfigPage from '../pages/config';
import ContactsRenewedPage from '../pages/ContactsRenewed';
import ChartPageByType from '../pages/Home';
import ConfigLogsContainer from '../containers/configLogs';
import ShowEmailPage from '../pages/showEmailPage';
import SendedMailsPage from '../pages/SendedMailsPage';
import ShowEmailPageTransactional from '../pages/showEmailPageTransactional';
import NotificationPage from '../pages/NotificationPage';
import WhatsappLogsPage from '../pages/WhatsappLogs/logs';
import WhatsappAnalyticsPage from '../pages/WhatsappLogs/analytics';
import EmailConfigPage from '../pages/EmailConfig';

const TransactionalAnalyticsPage = lazy(() =>
  import('pages/TransactionalAnalytics'),
);
const ComposeContainer = lazy(() => import('containers/compose'));
const ContactsContainer = lazy(() => import('containers/contacts'));
const AnalygicsContainer = lazy(() => import('containers/analytycs'));
const SendMailContainer = lazy(() => import('containers/send-mail'));
const SendWhatsappContainer = lazy(() => import('containers/send-whatsapp'));
const CalendarContainer = lazy(() => import('containers/calendar'));
const SmsContainer = lazy(() => import('containers/sms'));
const WhatsappContainer = lazy(() => import('containers/whatsapp'));
const SignIn = lazy(() => import('pages/SignIn'));
const ForgetPassword = lazy(() => import('pages/ForgetPassword'));
const RecoverPassword = lazy(() => import('pages/RecoverPassword'));
const NotFund = lazy(() => import('pages/NotFound'));
const SomePage = lazy(() => import('pages/SomePage'));
const UnsubscribePage = lazy(() => import('pages/Unsubscribe'));
const UnsubscribeSuccessPage = lazy(() => import('pages/UnsubscribeSuccess'));
const AbusePage = lazy(() => import('pages/Abuse'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicy'));
const ConfirmSubscriptionPage = lazy(() => import('pages/ConfirmSubscription'));
const WhatsappPage = lazy(() => import('pages/Whatsapp'));
const WhatsAppConfigPage = lazy(() => import('pages/WhatsAppConfig'));

const antdLang = {
  engKey: en_GB,
  itKey: it_IT,
};

const Routes = () => {
  return (
    <>
      <ToastContainer />
      <Suspense fallback={<Loading />}>
        <Switch>
          <ProtectedRoute component={NewHome} exact path={routesCodes.home} />
          <ProtectedRoute
            component={WhatsappPage}
            path={routesCodes.whatsapp}
          />
          <ProtectedRoute
            component={TransactionalAnalyticsPage}
            exact
            path={routesCodes.transactionalAnalytics}
          />

          <ProtectedRoute
            component={ComposeContainer}
            path={routesCodes.compose}
          />
          <ProtectedRoute component={ConfigPage} path={routesCodes.config} />
          <ProtectedRoute
            component={ConfigLogsContainer}
            path={routesCodes.configLogs}
          />
          <ProtectedRoute
            component={NotificationPage}
            path={routesCodes.notifications}
          />
          <ProtectedRoute
            component={ComposeContainer}
            path={routesCodes.composeTransactional}
          />
          <ProtectedRoute
            component={ContactsContainer}
            path={routesCodes.contacts}
          />
          <ProtectedRoute
            component={AnalygicsContainer}
            path={routesCodes.analytics}
          />
          <ProtectedRoute
            component={SendedMailsPage}
            path={routesCodes.sendedMails}
          />
          <ProtectedRoute
            component={SendMailContainer}
            path={routesCodes.mail}
          />
          <ProtectedRoute
            component={CalendarContainer}
            path={routesCodes.calendar}
          />
          <ProtectedRoute
            component={ChartPageByType}
            path={routesCodes.charts}
          />
          <ProtectedRoute component={SmsContainer} path={routesCodes.sms} />
          <ProtectedRoute
            component={WhatsappContainer}
            path={routesCodes.whatsappTemplate}
          />
          <ProtectedRoute
            component={WhatsAppConfigPage}
            path={routesCodes.whatsappConfig}
          />
          <ProtectedRoute
            component={SendWhatsappContainer}
            path={routesCodes.whatsappSend}
          />
          <ProtectedRoute
            component={WhatsappLogsPage}
            path={routesCodes.whatsappLogs}
          />
          <ProtectedRoute
            component={WhatsappAnalyticsPage}
            path={routesCodes.whatsappAnalytics}
          />
          <ProtectedRoute
            component={EmailConfigPage}
            path={routesCodes.emailConfig}
          />
          <Route component={SignIn} exact path={routesCodes.signIn} />
          <Route
            component={ForgetPassword}
            exact
            path={routesCodes.forgetPassword}
          />
          <Route
            component={RecoverPassword}
            exact
            path={routesCodes.recoverPassword}
          />
          {/* <Route component={NotFund} exact path='*' /> */}
          <Route component={SomePage} exact path={routesCodes.somePage} />
          <Route
            component={UnsubscribeSuccessPage}
            exact
            path={routesCodes.unsubscribeSuccess}
          />
          <Route
            component={ContactsRenewedPage}
            exact
            path={routesCodes.contactsRenewed}
          />
          <Route
            component={UnsubscribePage}
            exact
            path={routesCodes.unsubscribe}
          />
          <Route component={AbusePage} exact path={routesCodes.abuse} />
          <Route
            component={PrivacyPolicyPage}
            exact
            path={routesCodes.privacyWrong}
          />
          <Route
            component={PrivacyPolicyPage}
            exact
            path={routesCodes.privacy}
          />
          <Route
            component={ConfirmSubscriptionPage}
            exact
            path={routesCodes.confirmSubscription}
          />

          <Route
            path={'/report/charts/:id/:date/:title'}
            render={(props) => <ReportPdfPage {...props} />}
          />
          <Route
            path={'/report/contacts'}
            render={(props) => <ReportPdfContactsPage {...props} />}
          />
          <Route
            path={'/report/home'}
            render={(props) => <ReportPdfHomePage {...props} />}
          />
          <Route
            path={'/report/newsletter'}
            render={(props) => <ReportPdfNewsletterPage {...props} />}
          />
          <Route component={ShowEmailPage} exact path='/page/:token' />
          <Route
            component={ShowEmailPageTransactional}
            exact
            path='/transactional_page/:token'
          />
          <Route component={NotFund} exact path='*' />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
