import CustomBreadcrumb from '../../components/atoms/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './style.scss';
import { Link } from 'react-router-dom';

const SendedMailsPage = (props) => {
  const {
    match: {
      params: { count, date },
    },
  } = props;
  const { t } = useTranslation();
  const breadcrumbs = [
    { path: '/', name: t('home.home') },
    { path: '/mail', name: t('mail.sendNewsletter') },
  ];

  const decodeDate = decodeURIComponent(date);

  let text = '';

  if (decodeDate === 'now') {
    text = t('nowSend', { number: count });
  } else {
    text = t('byDate', {
      number: count,
      date: decodeDate.split(' ')[0],
      time: decodeDate.split(' ')[1],
    });
  }

  return (
    <div className='send-mail-page'>
      <div className='headerMain'>
        <div className='title'>
          <div className='text'>{t('mail.sendNewsletter')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      <div className='body'>
        <div className='displaymidleText'>
          {text}
          <Link to='/mail'>
            <Button className={'new-mail'}>{t('Nuovo Invio')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SendedMailsPage;
