import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';
import Calendar from 'react-calendar';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import useCalendar from './useCalendar';

const CalendarSection = () => {
  const { t } = useTranslation();
  const {
    value,
    onChange,
    loading,
    data,
    currentDomain,
    setStartDateValue,
    availableDates,
  } = useCalendar();

  return (
    <div className={styles.card}>
      <p className={styles.title}>{t('Calendario invio newsletter')}</p>
      <Spin spinning={loading}>
        <Row>
          <Col span={12}>
            <Calendar
              className={styles.calendar}
              locale={'it'}
              nextLabel={<ArrowRightOutlined />}
              next2Label={false}
              prevLabel={<ArrowLeftOutlined />}
              onChange={onChange}
              value={value}
              onActiveStartDateChange={({ activeStartDate }) =>
                setStartDateValue(moment(activeStartDate).format('YYYY-MM-DD'))
              }
              tileDisabled={({ date }) => {
                return !availableDates.includes(
                  moment(date).format('YYYY-MM-DD'),
                );
              }}
            />
          </Col>
          <Col span={12}>
            <div className={styles.dataSection}>
              <span className={styles.dataSection_title}>
                {moment(value).format('DD MMMM YYYY')}
              </span>
              <span className={styles.dataSection_description}>
                {/*{t('Nessun invio pianificato per oggi')}*/}
                <p>
                  {t('Newsletter email, contatti')}: {data.newsletterCount}
                </p>
                {currentDomain?.whatsapp?.length > 0 && (
                  <p>
                    {t('Newsletter whatsapp, contatti')}: {data.whatsappCount}
                  </p>
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default CalendarSection;
