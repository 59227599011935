import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/main-pure-logo.png';
import AnimalLogo from 'assets/images/sign_in_logo.png';
import './style.scss';
const { Option } = Select;

const ChangeDataTypeModal = ({
  domains,
  onSetCurrentType,
  setIsModalVisible,
  isModalVisible,
  currentSelectedDataType,
  currentNewsletter,
  currentNotification,
  currentSMS,
  currentWhatsapp,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState({ id: '' });
  const handleOk = () => {
    if (currentValue.id) {
      setIsModalVisible(false);
      const find = domains.find((item) => item.id === currentValue.id);
      onSetCurrentType(find);
    }
  };

  const handleCancel = () => {
    switch (currentSelectedDataType) {
      case 'newsletter': {
        if (currentNewsletter.id !== undefined) {
          setIsModalVisible(false);
        }
        break;
      }
      case 'notification': {
        if (currentNotification.id !== undefined) {
          setIsModalVisible(false);
        }
        break;
      }
      case 'sms': {
        if (currentSMS.id !== undefined) {
          setIsModalVisible(false);
        }
        break;
      }
      case 'whatsapp': {
        if (currentWhatsapp.id !== undefined) {
          setIsModalVisible(false);
        }
        break;
      }
    }
  };

  const onChangeDomain = (value) => {
    setCurrentValue({ id: value });
  };

  useEffect(() => {
    switch (currentSelectedDataType) {
      case 'newsletter': {
        setCurrentValue(currentNewsletter);
        break;
      }
      case 'notification': {
        setCurrentValue(currentNotification);
        break;
      }
      case 'sms': {
        setCurrentValue(currentSMS);
        break;
      }
      case 'whatsapp': {
        setCurrentValue(currentWhatsapp);
        break;
      }
    }
  }, [currentSelectedDataType]);

  let title = t('Seleziona il servizio');

  console.log('domains=', domains);

  return (
    <Modal
      className='set-domain-email-modal'
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      okText={t('layout.modalYesBtn')}
      cancelText={t('base.cancel')}
      okButtonProps={
        {
          // disabled: true,
          // loading: modalLoadingBtn
        }
      }
    >
      <div className='body'>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder={t('layout.changeDomain')}
          optionFilterProp='children'
          onChange={onChangeDomain}
          value={currentValue.id}
          required
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {domains.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className='modalLogoBackground'>
        <img className='logo' src={logo} alt='logo' />
      </div>
      <div className='modalAnimalLogoBackground'>
        <img className='logo' src={AnimalLogo} alt='logo' />
      </div>
    </Modal>
  );
};

export default ChangeDataTypeModal;
