import { all, takeLatest, fork, put } from 'redux-saga/effects';
import AuthApi from 'libs/api/auth';
import AuthService from 'services/Auth';
import routeCodes from 'common/constants/route-codes';

import * as types from './types';

const api = new AuthApi();

function* userLogin({ payload }) {
  try {
  
    const { history, data: formData, redirectUrl } = payload;
    const { data } = yield api.signIn({ data: formData });
    AuthService.setToken(data.token);
    yield put({
      type: types.USER_LOGIN_SUCCESS,
      payload: data,
    });
    if (redirectUrl) {
      history?.push(redirectUrl);
    } else {
      history?.push(routeCodes.home);
    }
  } catch (e) {
    yield put({
      type: types.USER_LOGIN_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
}

function* watchUserLoginRequest() {
  yield takeLatest(types.USER_LOGIN_REQUEST, userLogin);
}

export default function* userSagas() {
  yield all([fork(watchUserLoginRequest)]);
}
