import React, { useEffect, useState } from 'react';
import logo from 'assets/images/main_logo.png';
import './style.scss';
import ContactsApi from 'libs/api/contacts';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const api = new ContactsApi();

const ContactsRenewedPage = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  useEffect(() => {
    const renewed_token = new URLSearchParams(props.location.search).get(
      'renewed_token',
    );

    api
      .renewed({
        token: renewed_token,
      })
      .then((response) => {
        setMessage('Success');
      })
      .catch((error) => {
        console.log('error=', error);
        setMessage('Something went wrong');
      });
  }, []);
  return (
    <div className='unsubscribe-page'>
      {message}
      <Typography.Title level={1}>
        <Link to={'/'}>{t('Go to home page')}</Link>
      </Typography.Title>
      <div className='logo'>
        <img src={logo} />
        <div>
          <div className='title'>Advanced</div>
          <div className='subTitle'>Email marketing Solutions</div>
        </div>
      </div>
    </div>
  );
};

export default ContactsRenewedPage;
