import Api from 'libs/api';

class WhatsappApi extends Api {
  constructor(resource) {
    super(resource);
  }

  addToGroup(params) {
    return this.buildQuery('post', params, '/add_to_group');
  }

  deleteFromGroup(params) {
    return this.buildQuery('delete', params, '/delete_from_group');
  }
  getLogs(params) {
    return this.buildQuery('get', params, '/messages');
  }
  getMessageChartAnalytics(id, params) {
    return this.buildQuery('get', params, 'whatsapp/fetch_message_analytics');
  }
}

export default WhatsappApi;
