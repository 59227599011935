import Api from 'libs/api';

class NewsletterCompaniesApi extends Api {
  constructor(resource) {
    super(resource);
  }

  getPlaned(params) {
    return this.buildQuery(
      'get',
      {
        params,
      },
      '/planed',
    );
  }
  getTransactionalEmail(params) {
    return this.buildQuery(
      'get',
      {
        params,
      },
      '/page',
    );
  }
}

export default NewsletterCompaniesApi;
