import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import SMSApi from 'libs/api/sms';
import { useDomains } from 'redux/domain/use-domains';
const api = new SMSApi();
const colors = {
  send: '#00C49F',
  send_fail: '#FF8042',
  new: '#1fb268',
  fail: '#d80d0d',
  delivered: '#188ad0',
  read: '#d0b118',
};
const useDomainCharts = (id, date) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { currentSMS } = useDomains();
  // moment.locale(language);
  let secondDate = moment(date).add(1, 'week').isAfter(new Date())
    ? moment()
    : moment(date).add(1, 'week');
  const range = [
    moment(new Date(date), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    secondDate.format('YYYY-MM-DD'),
  ];
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(range);
  const [statuses, setStatuses] = useState([]);

  const [chartsData, setChartsData] = useState({
    todayData: [],
    yesterdayData: [],
    monthData: [],
  });

  const getDomainsAnalyticsData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          user_service_id: currentSMS.id,
        },
      };
      const { data } = await api.getNewsletterChartAnalytics(params);
      let todayData = [];
      let yesterdayData = [];
      let monthData = [];
      const statuses = data.statuses;
      setStatuses(statuses);
      let isEmpty = Object.entries(data?.data?.today || {}).every(
        (i) => i[1] === 0,
      );
      const today = data?.data?.today || {};
      statuses.forEach((i) => {
        if (today[i] === 0 || !today[i]) return;
        todayData.push({
          name: i.toUpperCase(),
          value: today[i],
          color: colors[i],
        });
      });
      if (todayData.length === 0) {
        todayData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }
      const yesterday = data?.data?.yesterday || {};
      statuses.forEach((i) => {
        if (yesterday[i] === 0 || !yesterday[i]) return;
        yesterdayData.push({
          name: i.toUpperCase(),
          value: yesterday[i],
          color: colors[i],
        });
      });
      if (yesterdayData.length === 0) {
        yesterdayData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }
      const month = data?.data?.month || {};
      statuses.forEach((i) => {
        if (month[i] === 0 || !month[i]) return;
        monthData.push({
          name: t(i.toUpperCase()),
          value: month[i],
          color: colors[i],
        });
      });
      if (monthData.length === 0) {
        monthData.push({
          name: '',
          value: 1,
          color: '#4a4a4a',
          isEmpty: true,
        });
      }
      setChartsData({
        todayData,
        yesterdayData,
        monthData,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, currentSMS, language]);

  return {
    chartsData,
    loading,
    dateRange,
    setDateRange,
    colors,
    statuses,
  };
};

export default useDomainCharts;
