import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthService from 'services/Auth';
import routeCodes from 'common/constants/route-codes';

import {
  loginUserRequest,
  resetSignInErrors,
  setLocalization,
} from './actions';
import {
  signInErrorsSelector,
  signInInProcessSelector,
  localizationSelector,
  userDataSelector,
} from './selectors';
import { useQuery } from '../../pages/reportPdfContacts';

export const useUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signInInProcess = useSelector(signInInProcessSelector);
  const signInErrors = useSelector(signInErrorsSelector);
  const localization = useSelector(localizationSelector);
  const user = useSelector(userDataSelector);
  let query = useQuery();

  function handleSignIn(payload) {
    dispatch(
      loginUserRequest({
        data: payload,
        history,
        redirectUrl: query.get('redirectUrl'),
      }),
    );
  }

  function resetErrors() {
    if (signInErrors) {
      dispatch(resetSignInErrors());
    }
  }

  function handleSetLocalization(localization) {
    dispatch(setLocalization(localization));
  }

  function handleLogout() {
    AuthService.logout();
    history.push(routeCodes.signIn);
  }

  return {
    signInInProcess,
    signInErrors,
    handleSignIn,
    localization,
    handleSetLocalization,
    user,
  };
};
