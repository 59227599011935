import { all, takeLatest, fork, put } from 'redux-saga/effects';
import DomainApi from 'libs/api/domains';
import * as types from './types';

const api = new DomainApi();

function* getDomainEmails({ payload }) {
  try {
    const { id } = payload;
    // const {
    //   data: { domain_emails },
    // } = yield api.getDomainEmails(id);
    // yield put({
    //   type: types.SET_DOMAINS_EMAIL,
    //   payload: domain_emails,
    // });
  } catch (e) {
    console.log(e);
  }
}

function* watchGetDomainRequest() {
  yield takeLatest(types.GET_DOMAINS_EMAIL_REQUEST, getDomainEmails);
}

export default function* domainEmailsSagas() {
  yield all([fork(watchGetDomainRequest)]);
}
