import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import moment from 'moment';
import { Select } from 'antd';
import useLogs from './useLogs';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import MyRangePicker from 'components/atoms/myRangePicker';
import MyInput from 'components/atoms/myInput';
import MySelect from 'components/atoms/mySelect';
import MyTable from 'components/atoms/myTable';
const { Option } = Select;

const WhatsappLogsPage = () => {
  const { t } = useTranslation();
  const {
    columns,
    pagination,
    logData,
    setPagination,
    loading,
    status,
    setStatus,
    search,
    setSearch,
    dateRange,
    setDateRange,
  } = useLogs();
  const statuses = [
    {
      key: 'all',
      text: 'All',
    },
    {
      key: 'new',
      text: t('New'),
    },
    {
      key: 'send',
      text: t('Send'),
    },
    {
      key: 'send_fail',
      text: t('Send fail'),
    },
    {
      key: 'fail',
      text: t('Fail'),
    },
    {
      key: 'read',
      text: t('Read'),
    },
    {
      key: 'delivered',
      text: t('Delivered'),
    },
  ];
  const breadcrumbs = [
    { path: '/', name: t('compose.home') },
    { path: '/whatsapp-logs', name: t('Whatsapp Logs') },
  ];
  return (
    <div className='analytics-page-logs-whatsapp'>
      <div className='header'>
        <div className='title'>
          <div className='text'>{t('Logs')}</div>
        </div>
        <div className='breadcrumbs'>
          <CustomBreadcrumb breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className='flex'>
        <MyRangePicker
          format='DD/MM/YYYY'
          defaultValue={[
            moment(new Date(dateRange[0]), 'DD/MM/YYYY'),
            moment(new Date(dateRange[1]), 'DD/MM/YYYY'),
          ]}
          onChange={(date, dateString) => setDateRange(dateString)}
        />
        <>
          <MyInput
            className='ml-2 w-56'
            placeholder='Phone'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <MySelect
            defaultValue='All'
            value={status.status}
            style={{ width: 120 }}
            placeholder='Status'
            onChange={(value) => setStatus(value)}
            className='ml-2'
          >
            {statuses.map((i) => (
              <Option key={i.key} value={i.key}>
                {i.text}
              </Option>
            ))}
          </MySelect>
        </>
      </div>
      <div className='right-side-whatsapp'>
        <br />
        <br />
        <br />
        <div className='analytics-table-whatsapp'>
          <MyTable
            loading={loading}
            bordered
            pagination={{
              position: ['topRight', 'none'],
              pageSize: pagination?.per_page || 10,
              onChange: (current) => setPagination((s) => ({ ...s, current })),
              total: pagination.total_pages * pagination?.per_page,
              showSizeChanger: false,
            }}
            columns={columns}
            rowKey='id'
            dataSource={logData || []}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsappLogsPage;
