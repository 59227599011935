import React from 'react';
import useDomainCharts from './useDomainCharts';
import { Button, Col, DatePicker, Empty, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
  Brush,
} from 'recharts';
import './style.scss';
import { DownloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const NewsletterChartsPage = (props) => {
  const { id = 20, date = '2022-03-16' } = props?.match?.params || {};
  const {
    chartsData,
    loading,
    dateRange,
    setDateRange,
    label,
    handleDownloadPDF,
  } = useDomainCharts(id, date);
  const { t } = useTranslation();

  return (
    <div className={'domainChart'}>
      <Row gutter={16}>
        <Col>
          <RangePicker
            defaultValue={[
              moment(new Date(dateRange[0])),
              moment(new Date(dateRange[1])),
            ]}
            onChange={(date, dateString) => setDateRange(dateString)}
            format={'DD/MM/YYYY'}
          />
        </Col>
        <Col>
          <Button
            className='header-btn header-btn-icon'
            type='primary'
            icon={<DownloadOutlined />}
            loading={loading}
            onClick={handleDownloadPDF}
          />
        </Col>
      </Row>
      <br />
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Sent mail over time')} ({chartsData.total.send})
            </Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.sendData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.sendData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.sendData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Open mail over time')} ({chartsData.total.opened})
            </Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.openedData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.openedData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.openedData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Failed mail over time')} ({chartsData.total.failed})
            </Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.failedData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.failedData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.failedData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {t('Clicked links over time')} ({chartsData.total.clicked})
            </Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              height: 400,
              width: '100%',
            }}
          >
            {chartsData.clickedData.length > 0 && (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  width={500}
                  height={400}
                  data={chartsData.clickedData}
                  margin={{
                    top: 5,
                    right: 100,
                    left: 20,
                    bottom: 120,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    tick={{
                      angle: -35,
                      textAnchor: 'end',
                      dominantBaseline: 'ideographic',
                    }}
                  />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Legend
                    verticalAlign='top'
                    wrapperStyle={{ lineHeight: '40px' }}
                  />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey={label} fill='#8884d8' />
                  <Brush y={320} dataKey='name' height={30} stroke='#8884d8' />
                </BarChart>
              </ResponsiveContainer>
            )}
            {chartsData.clickedData.length === 0 && (
              <Row justify='center' align='middle'>
                <Empty description={false} />
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default NewsletterChartsPage;
