import Api from 'libs/api';

class AuthApi extends Api {
  constructor() {
    super('auth');
  }

  signIn = (params = {}) => {
    return this.buildQuery('post', params, '/signin');
  };
  updatePassword = (data = {}) => {
    return this.buildQuery('put', { data }, '/password');
  };

  sendResetPassword = (params) => {
    return this.buildQuery('post', params, '/send_reset_password');
  };

  resetPassword = (params) => {
    return this.buildQuery('put', params, '/reset_password');
  };
}

export default AuthApi;
