import { useEffect, useState } from 'react';
import DomainApi from 'libs/api/domains';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import { useDomains } from 'redux/domain/use-domains';

const api = new DomainApi();

const useDomainCharts = (query) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  const range = [
    moment(query.get('date_from'), 'YYYY-MM-DD')
      .subtract(1, 'week')
      .format('YYYY-MM-DD'),
    moment(query.get('date_to'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
  ];
  const [dateRange] = useState(range);

  const [chartsData, setChartsData] = useState({
    totalContacts: [],
    unsubscribedContacts: [],
    autoRemovedContacts: [],
    newContacts: [],
    manuallyImportedContacts: [],
    importedContactsFromWfi: [],
    importedContactsFromWebsite: [],
    total: {
      auto_removed_contacts: 0,
      imported_contacts_from_website: 0,
      imported_contacts_from_wfi: 0,
      manually_imported_contacts: 0,
      new_contacts: 0,
      total_contacts: 0,
      unsubscribed_contacts: 0,
    },
  });

  const getDomainsAnalyticsData = async () => {
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          type: 'day',
        },
      };
      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      let formatType = 'DD/MM/YYYY';
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
        formatType = 'MMM/YYYY';
      }

      const addData = (arr, i) => {
        arr.push({
          name: moment(i[0], 'YYYY-MM-DD').format(formatType).toUpperCase(),
          [t('Contatti')]: i[1],
        });
      };

      const { data } = await api.getCompanyEmailsAnalytics(
        query.get('company'),
        params,
      );
      const totalContacts = [];
      const unsubscribedContacts = [];
      const autoRemovedContacts = [];
      const newContacts = [];
      const manuallyImportedContacts = [];
      const importedContactsFromWfi = [];
      const importedContactsFromWebsite = [];

      const {
        unsubscribed_contacts,
        total_contacts,
        auto_removed_contacts,
        new_contacts,
        manually_imported_contacts,
        imported_contacts_from_wfi,
        imported_contacts_from_website,
      } = data.data;

      const { total = {} } = data;

      Object.entries(total_contacts).forEach((i) => {
        addData(totalContacts, i);
      });

      Object.entries(unsubscribed_contacts).forEach((i) => {
        addData(unsubscribedContacts, i);
      });

      Object.entries(auto_removed_contacts).forEach((i) => {
        addData(autoRemovedContacts, i);
      });

      Object.entries(new_contacts).forEach((i) => {
        addData(newContacts, i);
      });

      Object.entries(manually_imported_contacts).forEach((i) => {
        addData(manuallyImportedContacts, i);
      });

      Object.entries(imported_contacts_from_wfi).forEach((i) => {
        addData(importedContactsFromWfi, i);
      });

      Object.entries(imported_contacts_from_website).forEach((i) => {
        addData(importedContactsFromWebsite, i);
      });

      setChartsData({
        totalContacts,
        unsubscribedContacts,
        autoRemovedContacts,
        newContacts,
        manuallyImportedContacts,
        importedContactsFromWfi,
        importedContactsFromWebsite,
        total,
      });
      const event = new CustomEvent('status', { detail: 'ok' });
      window.addEventListener('status', function (e) {
        console.log('status: ', e.detail);
      });
      setTimeout(() => {
        window.dispatchEvent(event);
      }, 5000);
    } catch (e) {
      console.log(e);
      const event = new CustomEvent('status', { detail: 'ok' });
      window.addEventListener('status', function (e) {
        console.log('status: ', e.detail);
      });
      setTimeout(() => {
        window.dispatchEvent(event);
      }, 5000);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, language]);

  return {
    chartsData,
  };
};

export default useDomainCharts;
