import { useEffect, useState } from 'react';
import DomainApi from 'libs/api/domains';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import Helpers from 'helpers';
import { useDomains } from 'redux/domain/use-domains';
const api = new DomainApi();

const useDomainCharts = (id, date) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  const { currentNewsletter } = useDomains();
  const range = [
    moment(new Date(), 'YYYY-MM-DD').subtract(1, 'week').format('YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
  ];
  const [label, setLabel] = useState(t('Messaggi/ora'));
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(range);

  const [chartsData, setChartsData] = useState({
    sendData: [],
    openedData: [],
    failedData: [],
    pendingData: [],
    clickedData: [],
    unsubscriptionData: [],
    deliveredData: [],
    newDataSend: [],
    total: {
      clicked: 0,
      delivered: 0,
      failed: 0,
      opened: 0,
      pending: 0,
      send: 0,
      unsubscription: 0,
    },
  });

  const getDomainsAnalyticsData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          domain_id: currentNewsletter.domain_id,
          type: 'hour',
        },
      };

      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      let formatType = 'DD/MM/YYYY HH:mm';
      let label = t('Messaggi/ora');
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
        formatType = 'DD/MM/YYYY';
        label = t('Messaggi/giorno');
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
        formatType = 'MMM/YYYY';
        label = t('Messaggi/mese');
      }
      setLabel(label);
      const { data } = await api.getMessageChartAnalytics(
        currentNewsletter.id,
        params,
      );
      const sendData = [];
      const openedData = [];
      const failedData = [];
      const pendingData = [];
      const clickedData = [];
      const unsubscriptionData = [];

      const { clicked, failed, opened, pending, send, unsubscription } =
        data.data;

      const { total } = data;

      const newDataSend = [];

      const addData = (arr, i) => {
        arr.push({
          name: moment(i[0], 'YYYY-MM-DD HH:mm')
            .format(formatType)
            .toUpperCase(),
          [label]: i[1],
        });
      };

      Object.entries(send).forEach((i) => {
        addData(sendData, i);
      });

      Object.entries(opened).forEach((i) => {
        addData(openedData, i);
      });

      Object.entries(failed).forEach((i) => {
        addData(failedData, i);
      });

      Object.entries(pending).forEach((i) => {
        addData(pendingData, i);
      });

      Object.entries(clicked).forEach((i) => {
        addData(clickedData, i);
      });

      Object.entries(unsubscription).forEach((i) => {
        addData(unsubscriptionData, i);
      });

      setChartsData({
        sendData,
        openedData,
        failedData,
        pendingData,
        clickedData,
        unsubscriptionData,
        newDataSend,
        total,
      });
    } catch (e) {
      console.log(e);
      setChartsData({
        sendData: [],
        openedData: [],
        failedData: [],
        pendingData: [],
        clickedData: [],
        unsubscriptionData: [],
        newDataSend: [],
        total: {},
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          domain_id: currentNewsletter.domain_id,
          type: 'hour',
          lang: language,
        },
      };

      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
      }

      const response = await api.getMessageChartAnalyticsPDF(
        currentNewsletter.id,
        params,
      );
      Helpers.downloadFile(response, 'report.pdf');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, currentNewsletter, language]);

  return {
    chartsData,
    loading,
    dateRange,
    setDateRange,
    label,
    handleDownloadPDF,
  };
};

export default useDomainCharts;
