import { useEffect } from 'react';
import AuthService from '../services/Auth';
import { useHistory } from 'react-router-dom';
import DomainApi from '../libs/api/domains';

const api = new DomainApi();

const useAuth = () => {
  const navigate = useHistory();
  useEffect(() => {
    (async () => {
      if (AuthService.getToken()) {
        try {
          const { data } = await api.getUser();
        } catch (e) {
          AuthService.logout();
          navigate.push(`/sign-in?redirectUrl=${navigate.location.pathname}`);
        }
      } else {
        AuthService.logout();
        navigate.push(`/sign-in?redirectUrl=${navigate.location.pathname}`);
      }
    })();
  }, []);

  return {};
};

export default useAuth;
