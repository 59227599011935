import React, { useState } from 'react';
import { Button, DatePicker, Row, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import LogsTable from './analytics-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './style.scss';
const { RangePicker } = DatePicker;

const ConfigLogsPage = ({
  setDateRange,
  modalLoadingBtn,
  analyticsData,
  pagination,
  setPagination,
  dateRange,
  total,
  handleDownload,
}) => {
  const [localDateRange, setLocalDateRange] = useState(dateRange);
  const { t, i18n } = useTranslation();

  const breadcrumbs = [
    { path: '/', name: t('home.home') },
    {
      path: '/config',
      name: t('Config'),
    },
    {
      path: '/config-logs',
      name: t('analytics.analytics'),
    },
  ];

  return (
    <div className='analytics-page'>
      <div className='header mb-3'>
        <div className='title'>
          <div className='text'>{t('analytics.analytics')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
        </div>

        <Row gutter={[8, 8]} className='flex filterBox'>
          <Col>
            <div className={'filterText'}>{t('Filtra per')}</div>
          </Col>
          <Col>
            <RangePicker
              size={'large'}
              disabled={modalLoadingBtn}
              format='DD/MM/YYYY'
              defaultValue={[
                moment(new Date(dateRange[0]), 'DD/MM/YYYY'),
                moment(new Date(dateRange[1]), 'DD/MM/YYYY'),
              ]}
              onChange={(date, dateString) => setLocalDateRange(dateString)}
              onOpenChange={(value) => {
                if (!value) {
                  setDateRange(localDateRange);
                }
              }}
            />
          </Col>
          <Col>
            <Button
              className='header-btn header-btn-icon'
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => {
                handleDownload();
              }}
            />
          </Col>
        </Row>
        <br />
      </div>
      <div className='right-side'>
        <div className='table' style={{ display: 'unset' }}>
          <LogsTable
            i18n={i18n}
            t={t}
            analyticsData={analyticsData}
            pagination={pagination}
            setPagination={setPagination}
            tableLoading={modalLoadingBtn}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigLogsPage;
