import { useEffect, useState } from 'react';
import DomainApi from 'libs/api/domains';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
import { useDomains } from '../../redux/domain/use-domains';
const api = new DomainApi();

const useDomainCharts = (query) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  const { currentDomain } = useDomains();
  const range = [
    moment(query.get('date_from'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    moment(query.get('date_to'), 'YYYY-MM-DD').format('YYYY-MM-DD'),
  ];
  const [dateRange] = useState(range);
  const [label, setLabel] = useState(t('Messaggi/ora'));

  const [chartsData, setChartsData] = useState({
    sendData: [],
    openedData: [],
    failedData: [],
    pendingData: [],
    clickedData: [],
    unsubscriptionData: [],
    deliveredData: [],
    total: {
      clicked: 0,
      delivered: 0,
      failed: 1,
      opened: 0,
      pending: 0,
      send: 1,
      unsubscription: 0,
    },
  });

  const getDomainsAnalyticsData = async () => {
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          domain_id: currentDomain.domain_id,
          type: 'hour',
        },
      };

      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      let formatType = 'DD/MM/YYYY HH:mm';
      let label = t('Messaggi/ora');
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
        formatType = 'DD/MM/YYYY';
        label = t('Messaggi/giorno');
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
        formatType = 'MMM/YYYY';
        label = t('Messaggi/mese');
      }
      setLabel(label);
      const { data } = await api.getNewsletterChartAnalytics(
        query.get('newsletter_company_id'),
        params,
      );
      const sendData = [];
      const openedData = [];
      const failedData = [];
      const pendingData = [];
      const clickedData = [];
      const unsubscriptionData = [];

      const { clicked, failed, opened, pending, send, unsubscription } =
        data.data;
      const { total = {} } = data;

      const addFunction = (arr, i) => {
        arr.push({
          name: moment(i[0], 'YYYY-MM-DD HH:mm')
            .format(formatType)
            .toUpperCase(),
          [label]: i[1],
        });
      };

      Object.entries(send).forEach((i) => {
        addFunction(sendData, i);
      });

      Object.entries(opened).forEach((i) => {
        addFunction(openedData, i);
      });

      Object.entries(failed).forEach((i) => {
        addFunction(failedData, i);
      });

      Object.entries(pending).forEach((i) => {
        addFunction(pendingData, i);
      });

      Object.entries(clicked).forEach((i) => {
        addFunction(clickedData, i);
      });

      Object.entries(unsubscription).forEach((i) => {
        addFunction(unsubscriptionData, i);
      });

      setChartsData({
        sendData,
        openedData,
        failedData,
        pendingData,
        clickedData,
        unsubscriptionData,
        total,
      });
      const event = new CustomEvent('status', { detail: 'ok' });
      window.addEventListener('status', function (e) {
        console.log('status: ', e.detail);
      });
      setTimeout(() => {
        window.dispatchEvent(event);
      }, 5000);
    } catch (e) {
      console.log(e);
      setChartsData({
        sendData: [],
        openedData: [],
        failedData: [],
        pendingData: [],
        clickedData: [],
        unsubscriptionData: [],
        total: {},
      });
      const event = new CustomEvent('status', { detail: 'ok' });
      window.addEventListener('status', function (e) {
        console.log('status: ', e.detail);
      });
      setTimeout(() => {
        window.dispatchEvent(event);
      }, 5000);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
  }, [dateRange, currentDomain, language]);

  return {
    chartsData,
    label,
  };
};

export default useDomainCharts;
