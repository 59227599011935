import * as types from './types';
import { primaryVariables } from '../../common/constants';

const initialState = {
  data: [],
  currentDomain: {},
  user: {},
  currentNewsletter: {},
  currentNotification: {},
  currentSMS: {},
  currentWhatsapp: {},
  currentSelectedDataType: '',
  isVisibleSetDataTypeModal: false,
  currentDataTypeList: [],
};

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGLE_DATA_TYPE_MODAL: {
      return {
        ...state,
        isVisibleSetDataTypeModal: action.payload,
      };
    }
    case types.SET_CURENT_SELECTED_TYPE: {
      return {
        ...state,
        currentSelectedDataType: action.payload,
      };
    }
    case types.SET_CURENT_DOMAIN: {
      const resetFields = {};
      const history = action.history;
      if (
        action.payload.id !== state.currentDomain.id &&
        state.currentDomain.id
      ) {
        resetFields.currentNewsletter = {};
        resetFields.currentNotification = {};
        resetFields.currentSMS = {};
        resetFields.currentWhatsapp = {};
        if (
          state.currentSelectedDataType &&
          state.currentSelectedDataType !== 'contacts' &&
          window.location.pathname !== '/' &&
          window.location.pathname !== '/config'
        ) {
          const { currentSelectedDataType } = state;
          let currentDataTypeList = [];
          switch (currentSelectedDataType) {
            case 'newsletter':
              currentDataTypeList =
                action.payload?.newsletters?.map((i) => ({
                  ...i,
                  name: i.email,
                  type: 'newsletter',
                })) || [];
              break;
            case 'notification':
              currentDataTypeList =
                action.payload?.notifications?.map((i) => ({
                  ...i,
                  name: i.email,
                  type: 'notification',
                })) || [];
              break;
            case 'sms':
              name = 'currentSMS';
              currentDataTypeList =
                action.payload?.sms?.map((i) => ({
                  ...i,
                  name: i.title,
                  type: 'sms',
                })) || [];
              break;
            case 'whatsapp':
              name = 'currentWhatsapp';
              currentDataTypeList =
                action.payload?.whatsapp?.map((i) => ({
                  ...i,
                  name: i.name,
                  type: 'whatsapp',
                })) || [];
              break;
          }
          if (currentDataTypeList.length > 0) {
            resetFields.isVisibleSetDataTypeModal = true;
          } else {
            history.push('/');
          }
          resetFields.currentDataTypeList = currentDataTypeList;
        }
      }

      return {
        ...state,
        currentDomain: action.payload,
        ...resetFields,
      };
    }
    case types.SET_DATA_TYPE_LIST: {
      return {
        ...state,
        currentDataTypeList: action.payload,
      };
    }
    case types.SET_CURENT_TYPE: {
      const { currentType } = action.payload;

      const { type } = currentType;
      let name = '';
      switch (type) {
        case 'newsletter':
          name = 'currentNewsletter';
          localStorage.setItem(
            primaryVariables.currentNewsletter,
            JSON.stringify(currentType),
          );
          break;
        case 'notification':
          name = 'currentNotification';
          localStorage.setItem(
            primaryVariables.currentNotification,
            JSON.stringify(currentType),
          );
          break;
        case 'sms':
          name = 'currentSMS';
          localStorage.setItem(
            primaryVariables.currentSMS,
            JSON.stringify(currentType),
          );
          break;
        case 'whatsapp':
          name = 'currentWhatsapp';
          localStorage.setItem(
            primaryVariables.currentWhatsapp,
            JSON.stringify(currentType),
          );
          break;
      }
      if (!name) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        [name]: currentType,
      };
    }
    case types.GET_DOMAINS:
      return {
        ...state,
        data: action.payload,
        user: action.user,
        currentDomain: action.payload.length !== 0 ? state.currentDomain : {},
      };
    case types.RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default domainReducer;
