import { DatePicker } from 'antd';
import styles from './style.module.scss';
const { RangePicker } = DatePicker;

const MyRangePicker = (props) => {
  const { className, children, ...rest } = props;
  return (
    <RangePicker className={styles.myTable + ` ${className}`} {...rest}>
      {children}
    </RangePicker>
  );
};

export default MyRangePicker;
