import * as types from './types';

const initialState = {
  data: [],
  currentEmail: {},
};

const domainEmails = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURENT_EMAIL: {
      return {
        ...state,
        currentEmail: action.payload,
      };
    }
    case types.SET_DOMAINS_EMAIL:
      return {
        ...state,
        data: action.payload,
        currentEmail: action.payload.length !== 0 ? action.payload[0] : {},
      };
    case types.UPDATE_UNREACHABLE_EMAIL:
      return {
        ...state,
        currentEmail: {
          ...state.currentEmail,
          auto_unreachable: !state.currentEmail.auto_unreachable,
        },
      };
    default:
      return state;
  }
};

export default domainEmails;
