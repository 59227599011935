import { Switch } from 'antd';
import styles from './style.module.scss';

const MySwitch = (props) => {
  const { className, text, ...rest } = props;
  return (
    <div className={styles.mySwitch}>
      <Switch className={`${className}`} {...rest} />
      <span>{text}</span>
    </div>
  );
};

export default MySwitch;
