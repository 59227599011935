import { useEffect, useState } from 'react';
import DomainApi from 'libs/api/domains';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/it';
const api = new DomainApi();

const useDomainCharts = (id, date, domain_id) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  // moment.locale(language);
  let secondDate = moment(date).add(1, 'week').isAfter(new Date())
    ? moment()
    : moment(date).add(1, 'week');
  const range = [
    moment(new Date(date), 'YYYY-MM-DD').format('YYYY-MM-DD'),
    secondDate.format('YYYY-MM-DD'),
  ];
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(range);

  const [chartsData, setChartsData] = useState({
    sendData: [],
    openedData: [],
    failedData: [],
    pendingData: [],
    clickedData: [],
    unsubscriptionData: [],
    deliveredData: [],
  });

  const getDomainsAnalyticsData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          date_from: dateRange[0],
          date_to: dateRange[1],
          domain_id: domain_id,
          type: 'hour',
        },
      };

      const firstDate = moment(dateRange[0], 'DD/MM/YYYY');
      const secondDate = moment(dateRange[1], 'DD/MM/YYYY');
      let formatType = 'DD/MM/YYYY HH:mm';
      if (secondDate.diff(firstDate, 'days') > 7) {
        params.params.type = 'day';
        formatType = 'DD/MM/YYYY';
      }

      if (secondDate.diff(firstDate, 'month', true) > 1) {
        params.params.type = 'month';
        formatType = 'MMM/YYYY';
      }

      const { data } = await api.getNewsletterChartAnalytics(id, params);
      const sendData = [];
      const openedData = [];
      const failedData = [];
      const pendingData = [];
      const clickedData = [];
      const unsubscriptionData = [];

      const { clicked, failed, opened, pending, send, unsubscription } =
        data.data;
      // console.log('data.data=', data.data);
      //

      const addFunction = (arr, i) => {
        arr.push({
          name: moment(i[0], 'YYYY-MM-DD HH:mm')
            .format(formatType)
            .toUpperCase(),
          [t('Count')]: i[1],
        });
      };

      Object.entries(send).forEach((i) => {
        addFunction(sendData, i);
      });

      Object.entries(opened).forEach((i) => {
        addFunction(openedData, i);
      });

      Object.entries(failed).forEach((i) => {
        addFunction(failedData, i);
      });

      Object.entries(pending).forEach((i) => {
        addFunction(pendingData, i);
      });

      Object.entries(clicked).forEach((i) => {
        addFunction(clickedData, i);
      });

      Object.entries(unsubscription).forEach((i) => {
        addFunction(unsubscriptionData, i);
      });

      setChartsData({
        sendData,
        openedData,
        failedData,
        pendingData,
        clickedData,
        unsubscriptionData,
      });
    } catch (e) {
      console.log(e);
      setChartsData({
        sendData: [],
        openedData: [],
        failedData: [],
        pendingData: [],
        clickedData: [],
        unsubscriptionData: [],
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await getDomainsAnalyticsData();
    const event = new CustomEvent('status', { detail: 'ok' });
    window.addEventListener('status', function (e) {
      console.log('status: ', e.detail);
    });
    setTimeout(() => {
      window.dispatchEvent(event);
    }, 5000);
  }, [domain_id]);

  return {
    chartsData,
    loading,
    dateRange,
    setDateRange,
  };
};

export default useDomainCharts;
