import React from 'react';
import CustomBreadcrumb from 'components/atoms/Breadcrumb';
import { useTranslation } from 'react-i18next';
import useConfig from './useConfig';
import { Button, Col, Divider, Form, Row, Select, Typography } from 'antd';
import styles from './style.module.scss';
import MySwitch from 'components/atoms/mySwitch';
import { Link } from 'react-router-dom';
import MyInput from '../../components/atoms/myInput';

const ConfigPage = () => {
  const { t } = useTranslation();
  const { loading, mailTemplates, form, handleSave } = useConfig();
  const breadcrumbs = [
    { path: '/', name: t('home.home') },
    { path: '/config', name: t('Config') },
  ];

  const onFinish = async (values) => {
    await handleSave(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.configPage}>
      <div className={styles.headerMain}>
        <div className={styles.title}>
          <div className={styles.text}>{t('Config')}</div>
          <div className='breadcrumbs'>
            <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <Row>
          <Col span={24}>
            <a
              href={'https://hub.360dialog.com/lp/migrate/SIH1ayPA'}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button type='primary' className={styles.saveBtn}>
                {t('Whatsapp Business')}
              </Button>
            </a>
          </Col>
          <Divider />
          <Col span={24}>
            <Form
              form={form}
              layout={'vertical'}
              name='basic'
              initialValues={{
                contact_activity_month: '',
                renewed_template: '',
                birthday_template: '',
                is_send_birthday: '',
                auto_unreachable: false,
                qnt_auto_unreachable: 0,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={t('Reminder')}
                    name='contact_activity_month'
                  >
                    <Select loading={loading} disabled={loading}>
                      <Select.Option value={0}>{t('Off')}</Select.Option>
                      <Select.Option value={6}>{t('6 mesi')}</Select.Option>
                      <Select.Option value={12}>{t('1 anno')}</Select.Option>
                      <Select.Option value={24}>{t('2 anni')}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('Template inattività')}
                    name='renewed_template'
                  >
                    <Select loading={loading} disabled={loading}>
                      {mailTemplates.map((template) => (
                        <Select.Option value={template.id}>
                          {template.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className={styles.btnContainer} span={8}>
                  <Link to={'/config-logs/rimail_renewed_notify'}>
                    <Button className={styles.saveBtn} type='primary'>
                      {t('Logs')}
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row align={'middle'} gutter={16}>
                <Col span={24}>
                  <Typography>{t('Sospendi i contatti in ko')}</Typography>
                </Col>
                <Col span={24}>
                  <Row align={'middle'} gutter={16}>
                    <Col>
                      <Form.Item
                        valuePropName={'checked'}
                        name='auto_unreachable'
                        className={styles.input}
                      >
                        <MySwitch text={t('dopo')} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label={''}
                        name='qnt_auto_unreachable'
                        className={styles.input}
                      >
                        <MyInput />
                      </Form.Item>
                    </Col>
                    <Col className={styles.bounceText}>{t('bounce')}</Col>
                  </Row>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  loading={loading}
                  className={styles.saveBtn}
                  type='primary'
                  htmlType='submit'
                >
                  {t('Save')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConfigPage;
